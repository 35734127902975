import {
  adminActions,
  chatActions,
  pharmacistActions,
  pharmacyActions,
  pharmacyAdminActions,
  professionalActions,
  userActions,
} from '../actions';
// User Array
const user = Object.values(userActions);
const pharmacist = Object.values(pharmacistActions);
const pharmacy = Object.values(pharmacyActions);
const admin = Object.values(adminActions);
const pharmacyAdmin = Object.values(pharmacyAdminActions);
const chat = Object.values(chatActions);
const professionals = Object.values(professionalActions);

const actionTrackerList = [
  ...user,
  ...pharmacist,
  ...pharmacy,
  ...admin,
  ...pharmacyAdmin,
  ...chat,
  ...professionals,
];

export default actionTrackerList;
