import React, { FC } from 'react';

import { Typography, Avatar, Box, SxProps } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {
  userPreferredTimeFormat,
  PharmacistLegendVariant,
  BookingState,
  logos,
  convertToDecimals,
  distanceUnit,
  ServiceTypes,
} from '@pharmaplan/common';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import WithSkeleton from '../../../common/WithSkeleton';
import CustomChip from '../../../common/CustomChip/CustomChip';
import { ReactComponent as FavIcon } from '../../../../assets/svg/favIconWithBorder.svg';
import { getHoursDiff } from '../../../Modals/WorkshiftDetails/utils';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useAdmin from '../../../../hooks/useAdmin';
import { SkeletonVariants } from '../../../../helpers/Constants';
import { timeString } from '../../../../helpers/Functions';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import Watchlist from '../../../common/Watchlist';
import strings from '../../../../localization';

import { IPharmacyCard } from '../PharamcyCard';
import classes from './style';

const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

const PharmacyCardContent: FC<IPharmacyCard> = ({
  type,
  event,
  successAction,
  CustomContent,
  showPharmacyName,
  customTitleStyle,
}) => {
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const allLogos = useAppSelector(logos);

  const { isAdmin } = useAdmin();

  const {
    startDate = '',
    endDate = '',
    pharmacyName,
    city,
    logoImage,
    favorite,
    bookingState,
    counterEndDate,
    counterStartDate,
    logoId,
    calculatedDistance,
    isInWatchlist = false,
    workShiftId,
  } = event ?? {};

  const logo = allLogos?.find((log) =>
    log.logoId === logoId)?.image;

  const isBooked = type === PharmacistLegendVariant.booked;

  const isUpdatedTiming = isBooked && bookingState === BookingState.UpdatedTiming;

  const eventChipConfig = {
    [PharmacistLegendVariant.workshift]: {
      label: strings.availableWorkshift,
    },
    [PharmacistLegendVariant.booked]: {
      label: strings.bookedWorkshift,
    },
    [PharmacistLegendVariant.requested]: {
      label: strings.requestedWorkshift,
    },
  };

  const timeConfig = {
    startDate: isUpdatedTiming ? counterStartDate : startDate,
    endDate: isUpdatedTiming ? counterEndDate : endDate,
  };

  const updatedStartDate = timeConfig?.startDate ?? '';
  const updatedEndDate = timeConfig?.endDate ?? '';

  const time = timeString(updatedStartDate, updatedEndDate, timeFormat);
  const hours = `${time} (${getHoursDiff(
    updatedStartDate,
    updatedEndDate,
  )} Hrs)`;

  const eventLabel = eventChipConfig[type as keyof typeof eventChipConfig].label;

  const SkeletonAvatar = WithSkeleton({
    successAction,
    variant: 'circular',
    height: 75,
    width: 75,
  })(Avatar);

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const SkeletonChip = WithSkeleton({
    successAction,
    variant: 'rounded',
  })(Box);

  const contentHeader = () => {
    if ((isBooked || isSelfService) || showPharmacyName) {
      return pharmacyName;
    }

    return city;
  };

  const distance = `${convertToDecimals(calculatedDistance)} ${distanceUnit}`;

  const notBookedAndNotAdmin = !isBooked && !isAdmin;
  const notAdminAndIsWorkshift = !isAdmin;

  return (
    <Box sx={classes.card}>
      <Box sx={classes.cardContent}>
        <Box sx={classes.avatarContainer}>
          <SkeletonAvatar
            src={logoId ? logo : logoImage}
            sx={classes.image}
            imgProps={{ style: { objectFit: 'contain' } }}
          />
          <SkeletonChip sx={classes.favIconContainer}>
            {favorite && <FavIcon />}
          </SkeletonChip>
        </Box>

        <Box sx={classes.infoContainer}>

          <SkeletonTypography sx={[classes.title, customTitleStyle] as SxProps}>
            {contentHeader()}
          </SkeletonTypography>

          {isBooked && (
            <SkeletonTypography sx={classes.addressText}>{city}</SkeletonTypography>
          )}

          <SkeletonTypography sx={classes.date}>
            {`${getDayMonthDateYear(startDate)}`}
          </SkeletonTypography>

          <SkeletonTypography sx={classes.date}>{hours}</SkeletonTypography>

          {notAdminAndIsWorkshift && (
            <SkeletonTypography sx={classes.location}>
              <Box sx={classes.locationContainer}>
                <LocationOnIcon sx={classes.icon} />
                {distance}
              </Box>
            </SkeletonTypography>
          )}

          {isUpdatedTiming && (
            <SkeletonTypography sx={classes.timeStrike}>
              {timeString(startDate, endDate, timeFormat)}
              (
              {getHoursDiff(startDate, endDate)}
              Hrs)
            </SkeletonTypography>
          )}

          {CustomContent ?? (
            <SkeletonChip sx={classes.chipContainer}>
              <CustomChip label={eventLabel} type={type} />
            </SkeletonChip>
          )}
        </Box>
      </Box>
      {notBookedAndNotAdmin && (
      <Box sx={classes.watchlistContainer}>
        <Watchlist
          initState={isInWatchlist}
          workshiftId={workShiftId ?? ''}
        />
      </Box>
      )}
    </Box>
  );
};

export default PharmacyCardContent;
