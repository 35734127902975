import React, { FC } from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import { getTypeOfUser, ServiceTypes } from '@pharmaplan/common';

import useProfileForm from '../../../hooks/ProfileForm/useProfileForm';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IContact, IGeneral } from '../types';
import strings from '../../../localization';

import ProfileFormGenerator from './ProfileFormGenerator';
import style from './style';

interface IProfileForm {
  selected: string;
  formik: any;
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
  isSignup?: boolean;
  handleEdit?: () => void;
}

const ProfileForm: FC<IProfileForm> = ({
  setSelected,
  selected,
  formik,
  isSignup = false,
  handleEdit,
}) => {
  const serviceType = ServiceHandler.getService();
  const { formConfig } = useProfileForm(formik, setSelected, handleEdit);
  const userType = useAppSelector(getTypeOfUser);

  const form = () => {
    if (isSignup) {
      return formConfig[serviceType][
        userType as keyof (typeof formConfig)[ServiceTypes]
      ].signup;
    }

    return formConfig[serviceType][
      userType as keyof (typeof formConfig)[ServiceTypes]
    ].profile;
  };

  const selectedForm = form()?.[selected as keyof typeof form];

  const handlerRender = () => {
    const {
      form: inForm,
      title = '',
      type: formType,
    } = selectedForm as IGeneral;

    if (!Array.isArray(selectedForm)) {
      switch (formType) {
        case 'single':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={inForm()}
              title={title}
              hideSave={isSignup}
            />
          );

        case 'table':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={inForm()}
              title={title}
              table
              hideSave={isSignup}
              isSignup={isSignup}
            />
          );

        case 'map':
          return (
            <ProfileFormGenerator
              formik={formik}
              items={inForm()}
              title={title}
              map
              setSelected={setSelected}
              hideSave
              isSignup={isSignup}
            />
          );

        default:
          return null;
      }
    } else {
      return (selectedForm as Array<IContact>)?.map((item) =>
        (
          <ProfileFormGenerator
            key={item.title}
            formik={formik}
            hideSave={item.hideSave}
            items={item.form()}
            title={item.title ?? ''}
          />
        ));
    }
  };

  return (
    <Grid sx={style.container} container>
      {(selectedForm as IGeneral).back && (
        <IconButton
          onClick={() =>
            setSelected?.((selectedForm as IGeneral)?.back ?? '')}
          style={style.backContainer}
        >
          <BackIcon />
          <Typography>{strings.back}</Typography>
        </IconButton>
      )}
      {handlerRender()}
    </Grid>
  );
};

export default ProfileForm;
