import ThemeConstants from '../../../theme/ThemeConstants';

const classes = {
  customSliderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  label: {
    color: ThemeConstants.common.grey850,
  },
};

export default classes;
