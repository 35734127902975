import React, { FC, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';

import {
  adminPharmacistPictures,
  convertToDecimals,
  getAdminRatingItems,
  ServiceTypes,
  TypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import strings from '../../../localization';

import EmptyIncompatibleMatches from '../IncompatibleMatches/EmptyIncompatibleMatches';
import useAdminRatings from '../../../hooks/Admin/Ratings/useAdminRatings';

import AllRatings from './AllRatings';
import PharmacistRating from './PharmacistRating';
import styles from './style';

interface IDetailedRatings {
  user: TypeOfUser;
}

const DetailedRatings: FC<IDetailedRatings> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { id } = state ?? {};

  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const {
    details,
    itemId,
    ratingItemAPI,
    ratingDetailsAPI,
    ratingAPI,
    userDetailsAPI,
    nameProperty,
    codeProperty,
  } = useAdminRatings(user);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const noData = details.length === 0;

  const backToPharmacistList = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSelfService) {
      dispatch(ratingItemAPI(id));
    } else {
      dispatch(getAdminRatingItems());
    }
    dispatch(ratingAPI(id));
    dispatch(adminPharmacistPictures([id]));
    dispatch(userDetailsAPI(id));
    dispatch(ratingDetailsAPI(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box
          component={IconButton}
          onClick={backToPharmacistList}
          sx={styles.backButtonContainer}
        >
          <BackIcon sx={styles.backIcon} />
          <Typography sx={styles.backButtonText}>
            {`${strings.backTo} ${strings.pharmacistList}`}
          </Typography>
        </Box>
        <Typography sx={styles.title}>{strings.pharmacistRating}</Typography>
        <PharmacistRating user={user} pharmacistId={id} />
      </Box>

      <Box sx={styles.container}>
        <Typography sx={styles.title}>{strings.allRatings}</Typography>
        {noData ? (
          <EmptyIncompatibleMatches message={strings.noDataAvailable} />
        ) : (
          <Box flexGrow={1}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {details.map((item) => {
                const {
                  note,
                  ratingPercentage,
                  createDate,
                  levels,
                  logoId,
                } = item ?? {};
                const name = item?.[
                  nameProperty as keyof typeof item
                ] as string;
                const userRatingId = item?.[
                  itemId as keyof typeof item
                ] as string;

                const code = item?.[codeProperty as keyof typeof item] as string;

                const percentage = parseFloat(ratingPercentage ?? '0');
                const rating = (percentage / 100) * 5;
                const formattedDate = getDayMonthDateYear(createDate);
                const time = getHourMinute(createDate, timeFormat);

                const date = `${strings.createdOn} ${formattedDate} | ${time}`;

                const info = {
                  ...item,
                  name,
                  pharmacyCode: code,
                  date,
                  rating: convertToDecimals(rating),
                  note,
                  logoId,
                  levels,
                  percentage,
                };

                return (
                  <Grid item xs={2} sm={4} md={4} key={userRatingId}>
                    <AllRatings user={user} info={info} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DetailedRatings;
