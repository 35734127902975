import React, { FC, Fragment } from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import {
  Language,
  language,
  IRatingDetailsLevel,
  TypeOfUser,
} from '@pharmaplan/common';

import useGetItems from '../../../../hooks/useGetItems';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import { noDataPlaceholder } from '../../../../helpers/Constants';

import styles from './style';
import { infoConfig } from './helper';
import AllLabelRatings from './AllLabelRatings';
import useAdminRatings from '../../../../hooks/Admin/Ratings/useAdminRatings';
import AdminRatingQuestion from '../AdminRatingQuestion/AdminRatingQuestion';

export interface IAllRatingsInfo {
  note: string;
  name: string;
  date: string;
  rating?: number | string;
  pharmacyCode: string;
  logoId: string;
  percentage: number;
  levels: Array<IRatingDetailsLevel>;
}

interface IAllRatings {
  info: IAllRatingsInfo;
  user: TypeOfUser;
}

const { en } = Language;

const AllRatings: FC<IAllRatings> = ({ info, user }) => {
  const { getImage } = useGetItems();

  const userLanguage = useAppSelector(language);
  const { userRatingItems, propertyId } = useAdminRatings(user);
  const { name, rating, pharmacyCode, date, note, logoId, levels } = info ?? {};
  const isEN = userLanguage === en;

  const image = getImage(logoId);

  const matchedItems = userRatingItems.reduce(
    (acc: Array<{ label: string; level?: number }>, curr) => {
      const { level } = levels.find(
        (item) =>
          item[propertyId as keyof typeof item]
            === curr[propertyId as keyof typeof curr],
      ) ?? {};
      const label = isEN ? curr.englishName : curr.frenchName;
      const obj = {
        level,
        label,
      };

      acc.push(obj);

      return acc;
    },
    [],
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Avatar
          src={image}
          sx={styles.avatar}
          imgProps={{ style: { objectFit: 'contain' } }}
        />

        <Box sx={styles.infoContainer}>
          {infoConfig({ name, rating, code: pharmacyCode, date }).map(
            (item) => {
              const { customComponent, sx, text, key } = item ?? {};
              const marginTop = key === 0 ? {} : styles.textGap;

              return (
                <Fragment key={key}>
                  {customComponent || (
                    <Typography sx={[sx, marginTop]}>{text}</Typography>
                  )}
                </Fragment>
              );
            },
          )}
        </Box>
      </Box>
      <Box sx={styles.ratingContainer}>
        {matchedItems.map((item) => {
          const { label, level } = item ?? {};

          return (
            <AllLabelRatings key={label} label={label} value={level ?? 0} />
          );
        })}
      </Box>

      <AdminRatingQuestion info={info} user={user} />

      <Box component="div">
        <Typography sx={styles.feedbackTitle}>
          {strings.pharmacyFeedback}
        </Typography>
        <Box sx={styles.reviewContainer}>
          <Typography sx={styles.feedback}>
            {note?.trim() || noDataPlaceholder}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AllRatings;
