import { ServiceTypes, ToUserTypings } from './Constants';
import Routes from './Routes';

const { self } = ServiceTypes;
const { professional, authentication } = Routes;
const { professionalCalendar, professionals } = professional;
const { authBasePath } = authentication;

const ServiceHandler = () => {
  let service = self;
  const setService = (inService: ServiceTypes) => {
    service = inService;
  };
  const getService = () => service;
  return { setService, getService };
};

export const serviceBasedRoutes = {
  pharmacistCalendar: professionalCalendar,
};

export const CreateTypes = ToUserTypings({
  Pharmacy: 'pharmacies',
  Pharmacist: 'professionals',
  Technicians: 'professionals',
  pharmacistStudents: 'professionals',
});

export const idStore = {
  id: 'professionalId',
  notesId: 'professionalPharmacyNoteId',
  capacityId: 'professionalCapacityId',
  clientRoute:Routes.admin.professionals,
  pictureRoute: Routes.admin.professionalPictures,
};

export default ServiceHandler();
