import React, { FC } from 'react';

import { Box } from '@mui/system';

import useAddWorkshift from '../../../../hooks/workshift/useAddWorkshift';
import AddUpdateEvents from '../../../common/AddUpdateEvents';
import AllowanceWorkshift from '../AllowanceWorkshift';
import NotesField from '../../../common/NotesField';

import classes from './styles';

/*
  ADD WORKSHIFT SCREEN FOR SELF-SERVICE
*/

interface IAdd {
  date: string;
}

const Add: FC<IAdd> = ({ date }) => {
  const { container } = classes;

  const { formik, setUserWorkTimings } = useAddWorkshift(date);

  return (
    <Box sx={container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        customDateName="datePicker"
        isSeries
      />
      <AllowanceWorkshift formik={formik} />
      <NotesField formik={formik} />
    </Box>
  );
};

export default Add;
