import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const classes = {
  toggleContainer: {
    mb: '24px',
  },

  title: {
    fontSize: '20px',
    marginBottom: '12px',
    fontWeight: 600,
    color: ThemeConstants.grey[800],
  },

  secondaryInputContainer: {
    display: 'flex',
  },

  horizontalAllowances: {
    '& fieldset': { border: 'none' },
    '& label': {
      flex: 2,
      marginBottom: 3,
    },
    '& .MuiTextField-root': {
      flex: 1,
    },
  },
  hourlyRate: {
    '& fieldset': { border: 'none' },
    '& label': {
      flex: 2,
      marginBottom: 3,
    },
    '& .MuiTextField-root': {
      flex: 1,
    },
  },

  travelTextField: {
    flex: 0.5,
  },

  travelLabel: {
    fontSize: 14,
    flex: 1,
    mt: 1,
    ml: 2,
    alignItems: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
