import { RootState } from '../store';

export const pharmacistPersist = (state: RootState) =>
  state.formPersist.pharmacist;
export const pharmacyPersist = (state: RootState) =>
  state.formPersist.pharmacy;
export const userPersist = (state: RootState) =>
  state.formPersist.user;

export const professionalPersist = (state: RootState) =>
  state.formPersist.professional;
