/* eslint-disable func-names */
import { imageTypes } from '@pharmaplan/common';
import { Slice } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';

interface IThisContext extends GlobalEventHandlers {
  height: number;
  width: number
}

const base64prefix = 'data:image/png;base64,';

const useEditorUtility = () => {
  function handleDrop(view: EditorView, event: DragEvent, slice: Slice, moved: boolean): boolean | void {
    const isFilePresent = !moved && event?.dataTransfer?.files[0];

    if (isFilePresent) {
      // if dropping external files
      const file = event.dataTransfer.files[0]; // the dropped file
      const fileSize = (file.size / 1024 / 1024).toFixed(4); // get the filesize in MB
      const isCorrectFormat = (file.type === imageTypes.jpeg || file.type === imageTypes.png);
      const isCorrectSize = fileSize < '10';

      if (isCorrectFormat && isCorrectSize) {
        const URL = window.URL || window.webkitURL;
        const img = new Image(); /* global Image */
        img.src = URL.createObjectURL(file);

        img.onload = function () {
          if ((this as IThisContext).width > 5000 || (this as IThisContext).height > 5000) {
            //  handle error
          } else {
            const reader = new FileReader();
            reader.onloadend = function () {
              const base64Image = (reader?.result as string)?.split(',')[1];
              const imageSrc = `${base64prefix}${base64Image}`; // Prepare the base64 image

              const { schema } = view.state;
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });
              const node = schema.nodes.image.create({ src: imageSrc });
              const { pos } = coordinates ?? {};
              const transaction = view.state.tr.insert(
                pos ?? 0,
                node,
              );
              return view.dispatch(transaction);
            };
            reader.readAsDataURL(file);
          }
        };
      } else {
        // handle Error
      }
      return true; // handled
    }
    return false; // not handled use default behaviour
  }
  return { handleDrop };
};

export default useEditorUtility;
