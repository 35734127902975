import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    backgroundColor: 'secondary.main',
    padding: '16px',
    borderRadius: '19px',
    mb: '14px',
  },
  commonColor: {
    color: 'common.grey850',
  },
  title: {
    fontSize: 16,
    mb: '16px',
  },
  label: {
    fontSize: 14,
    marginY: '4px',
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: '600',
  },
  labelContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  flexMax: {
    flex: 1,
  },
  arrivalContainer: {
    columns: 2,
    columnGap: 0,
  },
  arrival: {
    whiteSpace: 'nowrap',
    fontSize: 13,
    marginBottom: '8px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
