import React, { FC } from 'react';
import {
  AllowanceTypes,
  TypeOfBill,
  TypeOfSummary,
  pharmacistBookingDetails,
  pharmacyActions,
} from '@pharmaplan/common';
import { Typography } from '@mui/material';

import useSelfService from '../../../hooks/useSelfService';
import usePharmacyPreRequest from '../../../hooks/PharmacistProfile/usePharmacyPreRequest';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { ScreenTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import ServiceNote from '../ServiceNotes/ServiceNotes';
import PharmacistCard from '../PharmacistCard/PharmacistCard';
import PharmacistSummary from '../PharmacistSummary/PharmacistSummary';
import SoftwareExpertise from '../SoftwareExpertise/SoftwareExpertise';
import BillDetails from '../BillDetails/BillDetails';
import { IBookingRequestsList } from '../BookingRequestsList/BookingRequestsList';

import InfoNote from './InfoNote';
import classes from './styles';
import ReviewDetails from '../ReviewDetails';

export interface IPharmacistProfile extends IBookingRequestsList {
  workshiftId: string;
}

const { bill, counterOffer } = TypeOfBill;
const { custom } = AllowanceTypes;
const { pharmacistProfile } = ScreenTypes;
const { workshiftDetails } = TypeOfSummary;

const { getBookedPharmacistDetails: getBookedDetailsAction } = pharmacyActions;
const PharmacistProfile: FC<IPharmacistProfile> = ({
  eventId,
  type,
  eventIds,
  date,
  workshiftId,
}) => {
  const { isSelfService } = useSelfService();
  const { showButtonsWhen, isCounterOffer } = usePharmacyPreRequest({
    eventId,
    eventIds,
    type,
    date,
    workshiftId,
  });

  const details = useAppSelector(pharmacistBookingDetails);
  const {
    pharmaPlanPercentFees,
    mealAllowance,
    paidHourlyRate,
    softwares,
    counterNote,
    reviewDetails,
    accommodationAllowance,
  } = details ?? {};

  const billType = showButtonsWhen ? bill : counterOffer;

  const pharmaPlanFees = parseFloat(pharmaPlanPercentFees ?? '0').toPrecision(2) ?? 0;

  const parsedEvent = {
    ...details,
    fixedMealRate: mealAllowance,
    paidHourlyRate,
    fixedAccommodationRate: accommodationAllowance,
  };

  const billEvent = details;
  const { counterOfferTitle } = classes;

  return (
    <>
      <PharmacistCard
        successAction={pharmacyActions.getBookedPharmacistDetails}
        eventId={eventId}
        pharmacistDetails={details}
        type={type}
        date={date}
        workshiftId={workshiftId}
        eventIds={eventIds}
        screenType={pharmacistProfile}
      />

      <ReviewDetails reviewDetails={reviewDetails} />

      <SoftwareExpertise
        successAction={getBookedDetailsAction}
        softwareDetails={softwares}
      />

      {isCounterOffer && (
        <Typography sx={counterOfferTitle}>{strings.counterOffer}</Typography>
      )}

      <PharmacistSummary
        event={parsedEvent}
        summaryType={workshiftDetails}
        isCounterOffer={!!isCounterOffer}
        type={type}
      />

      <BillDetails
        successAction={getBookedDetailsAction}
        billType={billType}
        event={billEvent}
        isCounterOffer={!!isCounterOffer}
        percentFees={parseInt(pharmaPlanPercentFees ?? '', 10)}
      />

      {isSelfService && <InfoNote pharmaPlanFees={pharmaPlanFees} />}

      {showButtonsWhen && counterNote && <ServiceNote notes={counterNote} />}
    </>
  );
};

export default PharmacistProfile;
