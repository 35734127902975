import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  getBilling,
  IProfessionalBilling,
  professionalBillingInfo,
  updateBilling,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { BillingValidator } from '../../FormValidation';
import ProfileForm from '../../ProfileForm';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ProfileScreens } from '../../../../helpers/Constants';

export interface ICommonProfile {
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const Billing = ({ setSelected }: ICommonProfile) => {
  const dispatch = useAppDispatch();
  const billingInfo = useAppSelector(professionalBillingInfo);

  const handleSubmit = (values: FormikValues) => {
    dispatch(updateBilling(values as IProfessionalBilling));
  };

  const formik = useFormik({
    initialValues: {
      ...billingInfo,
      isAccountNumberChanged: false,
      accountNumber: '*'.repeat(5).concat(billingInfo.accountNumber),
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: BillingValidator(),
  });

  useEffect(() => {
    dispatch(getBilling());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = () => {
    formik.setFieldValue('accountNumber', '');
    formik.setFieldValue('isAccountNumberChanged', true);
  };

  return (
    <ProfileForm
      handleEdit={handleEdit}
      setSelected={setSelected}
      selected={ProfileScreens.billing}
      formik={formik}
    />
  );
};

export default Billing;
