import React, { FC } from 'react';

import Switch from '@mui/material/Switch';
import { Grid, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';

import classes from './style';

interface ICustomSwitch {
  formik: FormikProps<any>;
  name: string;
  label: string;
}

const CustomSwitch: FC<ICustomSwitch> = ({ formik, name, label }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, event.target.checked);
  };

  return (
    <Grid sx={classes.customSliderContainer} container>
      <InputLabel>{label}</InputLabel>
      <Switch
        onChange={handleChange}
        checked={!!formik?.values[name as keyof typeof formik]}
      />
    </Grid>
  );
};
export default CustomSwitch;
