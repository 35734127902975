import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  Language,
  language,
  RatingQuestionType,
  TypeOfUser,
} from '@pharmaplan/common';

import useAdminRatings from '../../../../hooks/Admin/Ratings/useAdminRatings';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { IAllRatingsInfo } from '../AllRatings/AllRatings';

import styles from './styles';
import { BinaryQuestionMap } from '../../../../helpers/Constants';

interface IAdminRatingQuestion {
  user: TypeOfUser;
  info: IAllRatingsInfo;
}

const AdminRatingQuestion: FC<IAdminRatingQuestion> = ({ user, info }) => {
  const { questionId, questions } = useAdminRatings(user);
  const answers = info['answers' as keyof typeof info] as unknown as Array<{
    answer: string;
  }>;
  const userLang = useAppSelector(language);
  const isEn = userLang === Language.en;

  return (
    <Box>
      {questions?.map((item) => {
        const {
          englishQuestion,
          frenchQuestion,
          questionType,
          questionValues,
        } = item ?? {};
        const id = item?.[questionId as keyof typeof item] as string;

        const { answer } = answers.find((answerItem) => {
          const idToFind = answerItem?.[questionId as keyof typeof answerItem];
          return idToFind === id;
        }) ?? {};

        const isBinaryQuestion = questionType === RatingQuestionType.binaryQuestion;
        let mappedAnswer = '';

        if (answer) {
          mappedAnswer = isBinaryQuestion
            ? BinaryQuestionMap?.[
                answer as unknown as keyof typeof BinaryQuestionMap
            ]
            : questionValues?.find(
              (questionVal) =>
                `${questionVal.key}` === answer,
            )?.value ?? '';
        }

        return (
          <Box key={id} sx={styles.container}>
            <Typography sx={styles.question}>
              {isEn ? englishQuestion : frenchQuestion}
            </Typography>
            <Typography sx={styles.answer}>
              -
              {mappedAnswer}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default AdminRatingQuestion;
