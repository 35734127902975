/* eslint-disable consistent-return */
import React, { FC, useEffect, useMemo } from 'react';

import { Box } from '@mui/system';

import {
  AdminLegendVariant,
  PharmacistLegendVariant,
  TypeOfBill,
  TypeOfSummary,
  adminActions,
  adminBookedPharmacistDetails,
  adminBookingDetails,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { ReactComponent as Calendar } from '@pharmaplan/common/assets/icons/calendarIconNew.svg';
import strings from '../../../../localization';
import ServiceCard from '../../../SelfService/ServiceCard';
import CustomGridLayout from '../../../common/CustomGridLayout';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { ScreenTypes } from '../../../../helpers/Constants';
import ThemeConstants from '../../../../theme/ThemeConstants';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import { getHourMinuteSeconds } from '../../../../helpers/Functions';
import PharmacistCard from '../../../SelfService/PharmacistCard';
import SoftwareExpertise from '../../../SelfService/SoftwareExpertise';
import PharmacistSummary from '../../../SelfService/PharmacistSummary';
import BillDetails from '../../../SelfService/BillDetails';
import {
  renderScreen,
  setBack,
  setFooterShow,
} from '../../../../actions/drawerActions';
import useDetailsPagination from '../../../../hooks/Admin/useDetailsPagination';
import { getMultipleEventAdd } from '../../../../selectors/calendarSelector';
import useOptimizedAvatar from '../../../../hooks/useOptimizedAvatar';
import useGetItems from '../../../../hooks/useGetItems';

import styles from './styles';
import ReviewDetails from '../../../SelfService/ReviewDetails';

interface IViewBookedDetails {
  bookingId: string;
  bookingIdList?: Array<string> | null;
}

const {
  getBookedPharmacistDetails,
  adminPharmacistPictures: adminPharmacistPicturesAction,
} = adminActions;

const ViewBookedDetails: FC<IViewBookedDetails> = ({
  bookingId,
  bookingIdList,
}) => {
  const dispatch = useAppDispatch();
  const enabledMultipleAdd = useAppSelector(getMultipleEventAdd);

  const { id } = useDetailsPagination(bookingIdList);
  const { getPharmacistImage } = useGetItems();

  const isNotFromMap = !id;
  const eventId = id ?? bookingId;

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const event = useAppSelector(adminBookingDetails);
  const {
    experience,
    createDate,
    bookingCost,
    email,
    address,
    province,
    softwares,
    startDate,
    postalCode,
    licenseNumber,
    city,
    pharmacistId,
    reviewDetails,
  } = event;

  const profilePicture = getPharmacistImage(pharmacistId);

  const goToBookingList = () => {
    dispatch(
      renderScreen({
        screenNumber: 1,
        screenType: ScreenTypes.bookingList,
        date: startDate,
      }),
    );
  };

  useEffect(() => {
    dispatch(adminBookedPharmacistDetails(eventId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (enabledMultipleAdd) {
      dispatch(setFooterShow(false));
      dispatch(setBack(null));
      return;
    }
    if (isNotFromMap) {
      dispatch(setBack(goToBookingList));
      dispatch(setFooterShow(false));
    }
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const pharmacistDetails = [
    { key: strings.yearsOfExperience, value: `${experience}` },
    { key: strings.licenseNo, value: licenseNumber },
    { key: strings.email, value: email },
    {
      key: strings.address,
      value: `${address}, ${city}, ${province}, ${postalCode}`,
    },
    {
      key: strings.formatString(strings.createdFormat, strings.date) as string,
      value: getDayMonthDateYear(createDate),
      adornments: <Calendar fill={ThemeConstants.common.grey850} />,
    },
    {
      key: strings.formatString(strings.createdFormat, strings.time) as string,
      value: getHourMinuteSeconds(createDate, timeFormat),
    },
  ];

  const fullEvent = {
    ...event,
    cost: bookingCost,
  };

  const pIds = useMemo(() =>
    [pharmacistId], [pharmacistId]);

  useOptimizedAvatar(pIds);

  return (
    <Box sx={styles.container}>
      <PharmacistCard
        eventIds={bookingIdList ?? []}
        eventId={eventId}
        type={PharmacistLegendVariant.booked}
        pharmacistDetails={{
          ...event,
          profilePicture,
        }}
        screenType={ScreenTypes.pharmacistProfile}
        successAction={getBookedPharmacistDetails}
        imageSuccessAction={adminPharmacistPicturesAction}
      />
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        content={(
          <CustomGridLayout
            successAction={getBookedPharmacistDetails}
            title={strings.pharmacistDetails}
            config={pharmacistDetails}
          />
        )}
      />
      <ReviewDetails reviewDetails={reviewDetails} />
      <SoftwareExpertise
        softwareDetails={softwares}
        successAction={getBookedPharmacistDetails}
      />
      <PharmacistSummary
        event={event}
        summaryType={TypeOfSummary.workshiftDetails}
        type={AdminLegendVariant.requested}
      />
      <BillDetails
        successAction={getBookedPharmacistDetails}
        billType={TypeOfBill.finalBill}
        event={fullEvent}
      />
    </Box>
  );
};

export default ViewBookedDetails;
