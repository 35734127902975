import React, { FC } from 'react';

import { FormikProps } from 'formik';

import {
  AllowancesNames,
  AllowanceTypes,
  currency,
  distanceUnit,
} from '@pharmaplan/common';

import CustomTextField from '../../../common/CustomTextField';
import strings from '../../../../localization';
import style from './style';

const { travel, accommodation } = AllowanceTypes;
const {
  accommodationAllowance,
  travelAllowanceAmount,
  travelAllowanceRatePerKm,
} = AllowancesNames;

enum TravelAllowanceSub {
  valuePerKm = 0,
  travelAmount = 2,
}
enum AccommodationAllowanceSub {
  accommodationValuePerNight = 2,
}

interface IAllowanceSubTravel {
  type: AllowanceTypes.travel;
  subType: TravelAllowanceSub;
}

interface IAllowanceSubAccommodation {
  type: AllowanceTypes.accommodation;
  subType: AccommodationAllowanceSub;
}

type IAllowanceSubComponent = (
  | IAllowanceSubAccommodation
  | IAllowanceSubTravel
) & { formik: FormikProps<any> };

const AllowanceSubComponent: FC<IAllowanceSubComponent> = ({
  type,
  subType,
  formik,
}) => {
  const renderConfig: { [key: number]: { [key: number]: React.ReactElement } } = {
    [travel]: {
      [TravelAllowanceSub.travelAmount]: (
        <CustomTextField
          formik={formik}
          name={travelAllowanceAmount}
          id={travelAllowanceAmount}
          placeholder={' '}
          suffix={currency}
          customClass={style.horizontalAllowances}
          horizontal
          label={strings.amount}
        />
      ),
      [TravelAllowanceSub.valuePerKm]: (
        <CustomTextField
          formik={formik}
          name={travelAllowanceRatePerKm}
          id={travelAllowanceRatePerKm}
          suffix={`${currency}/${distanceUnit}`}
          label={strings.valuePerKm}
          placeholder={' '}
          customClass={style.horizontalAllowances}
          horizontal
        />
      ),
    },
    [accommodation]: {
      [AccommodationAllowanceSub.accommodationValuePerNight]: (
        <CustomTextField
          formik={formik}
          name={accommodationAllowance}
          id={accommodationAllowance}
          suffix={`${currency}/${strings.night}`}
          placeholder={' '}
          label={strings.valuePerNight}
          customClass={style.horizontalAllowances}
          horizontal
        />
      ),
    },
  };

  return renderConfig[type][subType] ?? <div />;
};
export default AllowanceSubComponent;
