import { Box } from '@mui/system';
import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import styles from './styles';
import CustomTextField from '../CustomTextField';
import CustomButton from '../CustomButton';
import strings from '../../../localization';
import { Constants } from '../../../helpers/Constants';

const { text } = Constants.variant;

interface IConfirmTextField {
  showEdit?: boolean;
  handleClick: () => void;
  name: string;
  formik: FormikProps<any>;
  startAdornment?: React.ReactNode;
  xs: number;
  disabled: boolean;
  label: string;
  maxLength?: number;
}

const ConfirmTextField: FC<IConfirmTextField> = ({
  showEdit = true,
  handleClick,
  name,
  formik,
  startAdornment,
  xs,
  disabled,
  label,
  maxLength,
}) =>
  (
    <Grid xs={xs} container>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.fieldContainer}>
          <CustomTextField
            maxLength={maxLength}
            customTextFieldStyle={styles.field}
            startAdornment={startAdornment}
            formik={formik}
            label={label}
            id={name}
            disabled={disabled}
            name={name}
          />
        </Box>

        {showEdit && (
        <Box sx={styles.buttonContainer}>
          <CustomButton
            onClick={handleClick}
            label={strings.edit}
            variant={text}
            customButtonStyle={styles.buttonLabel}
          />
        </Box>
        )}
      </Box>
    </Grid>
  );

export default ConfirmTextField;
