import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import {
  IRatingQuestions,
  Language,
  language,
  RatingQuestionType,
} from '@pharmaplan/common';
import { radioButtonMapper } from '@pharmaplan/common/helpers/utils';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomRadioButton from '../../../common/CustomRadioButton';
import strings from '../../../../localization';

import styles from './styles';

interface IRatingQustions {
  professionalRatingQuestions: Array<IRatingQuestions>;
  formik: FormikProps<any>;
  idType: string;
}

const { en } = Language;
const { binaryQuestion } = RatingQuestionType;

const RatingQuestions: FC<IRatingQustions> = ({
  professionalRatingQuestions,
  formik,
  idType,
}) => {
  const userLanguage = useAppSelector(language);

  const isEN = userLanguage === en;

  const radioItems = [
    { label: strings.yes, value: 1 },
    { label: strings.no, value: 0 },
  ];

  return (
    <Box component="div">
      {professionalRatingQuestions?.map((item) => {
        const id = (item[idType as keyof typeof item] as string) ?? '';
        const {
          englishQuestion,
          frenchQuestion,
          questionType,
          questionValues,
        } = item ?? {};

        const question = isEN ? englishQuestion : frenchQuestion;
        const radioConfig = questionType === binaryQuestion
          ? radioItems
          : radioButtonMapper(questionValues ?? [], 'key', 'value');

        return (
          <Box key={id} mt="24px">
            <Typography sx={styles.question}>{question}</Typography>
            <CustomRadioButton
              name={id}
              formik={formik}
              radioItems={radioConfig}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default RatingQuestions;
