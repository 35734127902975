import { Schema } from 'yup';
import strings from '../../localization';
import {
  ContactValidator,
  GeneralValidator,
  professionalCorporatedValidator,
  ProfessionalValidator,
  SoftwareValidator,
} from './PharmacistSignupFlow/Validator';
import {
  accountingValidator,
  coordinateValidator,
  pharmacyInfoValidator,
  primaryValidator,
  secondaryValidator,
} from './PharmacySignupFlow/Validator';

type Steps = Record<any, { type: string; label: string; validator?: Schema }>;

export const pharmacistSteps = (): Steps =>
  ({
    0: {
      type: 'general',
      label: strings.general,
      validator: GeneralValidator(),
    },
    1: {
      type: 'professionalInfo',
      label: strings.professionalInfo,
      validator: ProfessionalValidator(),
    },
    2: {
      type: 'selectedSoftwares',
      label: strings.software,
      validator: SoftwareValidator(),
    },
    3: {
      type: 'contacts',
      label: strings.contact,
      validator: ContactValidator(),
    },
  });

export const professionalSteps = (): Steps =>
  ({
    0: {
      type: 'general',
      label: strings.general,
      validator: GeneralValidator(),
    },
    1: {
      type: 'professionalInfo',
      label: strings.professionalInfo,
      validator: professionalCorporatedValidator(),
    },
    2: {
      type: 'selectedSoftwares',
      label: strings.software,
      validator: SoftwareValidator(),
    },
    3: {
      type: 'contacts',
      label: strings.contact,
      validator: ContactValidator(),
    },
  });

export const pharmacySteps = (): Steps =>
  ({
    0: {
      type: 'pharmacyInfo',
      label: strings.general,
      validator: pharmacyInfoValidator(),
    },
    1: {
      type: 'coordinates',
      label: strings.coordinates,
      validator: coordinateValidator(),
    },
    2: {
      type: 'primary',
      label: strings.primary,
      validator: primaryValidator(),
    },
    3: {
      type: 'secondary',
      label: strings.secondary,
      validator: secondaryValidator(),
    },
    4: {
      type: 'accounting',
      label: strings.accounting,
      validator: accountingValidator(),
    },
  });
