import React, { FC, useState } from 'react';

import { Box } from '@mui/system';
import { FormikProps } from 'formik';

import { SocialLoginType } from '@pharmaplan/common';
import { ReactComponent as GoogleEmailIcon } from '../../../assets/svg/googleEmailIcon.svg';
import { ReactComponent as PharmaPlanEmailIcon } from '../../../assets/svg/pharmaplanEmail.svg';
import { ReactComponent as AppleEmailIcon } from '../../../assets/svg/appleLogoSocial.svg';
import { ReactComponent as FacebookEmailIcon } from '../../../assets/svg/facebookEmailIcon.svg';
import useChangeEmail from '../../../hooks/Admin/useChangeEmail';
import ConfirmTextField from '../ConfirmTextField';
import strings from '../../../localization';

import styles from './styles';

interface IChangeEmailField {
  formik: FormikProps<any>;
  fieldName: string;
  xs: number;
  preventChangeEmail?: boolean;
}

const { facebook, google, apple } = SocialLoginType;
const pharmaplan = 0;

const ChangeEmailField: FC<IChangeEmailField> = ({
  formik,
  fieldName,
  xs,
  preventChangeEmail,
}) => {
  const loginType = formik.values.signupType ?? pharmaplan;

  const [disabled, setDisabled] = useState(true);

  const { confirmChangeEmail } = useChangeEmail(setDisabled, loginType);

  const icons = {
    [facebook]: FacebookEmailIcon,
    [google]: GoogleEmailIcon,
    [pharmaplan]: PharmaPlanEmailIcon,
    [apple]: AppleEmailIcon,
  };

  const Icon = icons[loginType as keyof typeof icons];

  return (
    <ConfirmTextField
      disabled={disabled}
      handleClick={confirmChangeEmail}
      startAdornment={(
        <Box sx={styles.startAdornment}>
          <Icon />
        </Box>
      )}
      name={fieldName}
      formik={formik}
      xs={xs}
      showEdit={!preventChangeEmail}
      label={strings.email}
    />
  );
};

export default ChangeEmailField;
