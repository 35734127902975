import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { IReviewDetails } from '@pharmaplan/common/models/Models';
import strings from '../../../localization';
import styles from './styles';

interface IReviewDetailsComp {
  reviewDetails: IReviewDetails | undefined;
}

const ReviewDetails: FC<IReviewDetailsComp> = ({ reviewDetails }) => {
  const {
    confirmedBookings: noOfContracts,
    cancellations,
    cancellationPercent,
    totalBookings = 0,
    arrivalTotalReviews = 0,
    questionValues = [],
    arrivals,
  } = reviewDetails ?? {};
  return (
    <>
      <Box sx={styles.container}>
        <Typography sx={[styles.title, styles.bold]}>
          {strings.professionalProfileReview}
        </Typography>
        <Box sx={styles.labelContainer}>
          <Typography sx={[styles.label, styles.commonColor, styles.flexMax]}>
            {strings.noOfContract}
          </Typography>
          <Typography
            sx={[styles.label, styles.commonColor, styles.bold, styles.flexMax]}
          >
            {noOfContracts}
          </Typography>
        </Box>
        <Box sx={styles.labelContainer}>
          <Typography sx={[styles.label, styles.commonColor, styles.flexMax]}>
            {strings.cancellations}
          </Typography>
          <Typography
            sx={[styles.label, styles.commonColor, styles.bold, styles.flexMax]}
          >
            {cancellations}
            {' '}
            (
            {cancellationPercent}
            %
            {' '}
            {strings.formatString(strings.basedOnNBookings, totalBookings)}
            )
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.container}>
        <Typography sx={[styles.title, styles.bold]}>
          {strings.professionalPunctualityBasedBookings}
          {' '}
          (
          {strings.formatString(strings.basedOnNReviews, arrivalTotalReviews)}
          )
        </Typography>
        <Box sx={styles.arrivalContainer}>
          {questionValues.map((item) => {
            const { key, value } = item ?? {};
            return (
              <Typography
                key={key}
                sx={[styles.commonColor, styles.arrival]}
              >
                {value}
                :
                {' '}
                {arrivals?.[key] ?? '-'}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ReviewDetails;
