import { AllowanceEnums, SecondaryInputTypes } from '@pharmaplan/common';
import strings from '../../../../localization';

const { meal, travel, accommodation, emergency } = AllowanceEnums;
const { field, dropdown } = SecondaryInputTypes;

export const allowanceConfig = [
  {
    key: meal,
    label: strings.mealAllowances,
    secondaryInput: field,
  },
  {
    key: travel,
    label: strings.travelAllowances,
    secondaryInput: dropdown,
  },
  {
    key: accommodation,
    label: strings.accommodationAllowances,
    secondaryInput: dropdown,
  },
  {
    key: emergency,
    label: strings.emergencyServiceFees,
    secondaryInput: field,
  },
];
