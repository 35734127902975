import React from 'react';

import { Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { getTypeOfUser } from '@pharmaplan/common';
import { useNavigate, useLocation } from 'react-router-dom';
import { reportItems, toolbarItems } from '../config';
import { ReactComponent as ReportsIcon } from '../../../assets/svg/reportsIcon.svg';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import CustomMenuList from '../../common/CustomMenuList';

import { Constants } from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAddMultiple from '../../../hooks/useAddEvent';

import styles from './styles';

const { reports } = Constants.paths;

const NavToolBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { disableAddEvents, isAddEvents } = useAddMultiple();

  const userType = useAppSelector(getTypeOfUser);

  const { pathname } = location ?? {};

  const isReports = pathname === reports;

  const {
    bottomNavbar,
    bottomNavbarItemActive,
    bottomNavbarItemIcon,
    iconLabel,
    keyboardIcon,
    iconButtonGridContainer,
    iconButtonRightBorder,
    bottomNavbarItem,
  } = styles;

  const { flex } = genericClasses;

  return (
    <Toolbar sx={bottomNavbar} disableGutters>
      {/* left toolbar buttons */}
      <Grid xs={6} item sx={flex}>
        {toolbarItems(userType).map((item) => {
          const { key, label, Icon, path, id, subPaths } = item;
          const isActive = pathname === path || subPaths?.includes(pathname);

          return (
            <Grid item key={key} sx={iconButtonGridContainer}>
              <IconButton
                id={id}
                sx={[
                  bottomNavbarItem,
                  isActive && !isAddEvents ? bottomNavbarItemActive : {},
                ]}
                color="inherit"
                size="small"
                onClick={() => {
                  disableAddEvents();
                  navigate(path);
                }}
              >
                <Icon style={bottomNavbarItemIcon} />
                <Typography sx={iconLabel}>{label}</Typography>
              </IconButton>
              <Box component="div" sx={iconButtonRightBorder} />
            </Grid>
          );
        })}

        <CustomMenuList
          id="reports"
          items={reportItems(navigate, userType)}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          disableRipple={false}
          customIconButtonStyle={[
            bottomNavbarItem,
            isReports ? bottomNavbarItemActive : {},
          ]}
        >
          <Grid item sx={iconButtonGridContainer}>
            <ReportsIcon style={bottomNavbarItemIcon} />
            <Typography sx={iconLabel}>{strings.reports}</Typography>
            <KeyboardArrowDownIcon sx={keyboardIcon} />
          </Grid>
        </CustomMenuList>
      </Grid>
    </Toolbar>
  );
};

export default NavToolBar;
