import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    mb: '24px',
  },
  textAlign: {
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
    color: 'grey.500',
    mb: '16px',
  },
  description: {
    fontSize: 18,
    fontWeight: '500',
    mb: '32px',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    columnGap: '24px',
  },
  proceed: {
    width: 112,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
