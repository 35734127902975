import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    mb: 1,
  },
  question: {
    fontSize: 12,
    color: 'grey.800',
    fontWeight: '500',
  },
  answer: {
    fontSize: 11,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
