import { createAsyncThunk } from '@reduxjs/toolkit';
import ProfessionalController from '../controllers/ProfessionalController';
import { statusDispatch } from './globalActions';
import { IRatePharmacyParams } from '../models';

export const professionalActions = {
  rate: 'professional/rate',
  rating: 'professional/rating',
  ratingItems: 'professional/ratingitems',
  bookedRating: 'professional/bookedRating',
};

export const getPharmacyRating = createAsyncThunk(
  professionalActions.rating,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await ProfessionalController.rating(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyBookedRating = createAsyncThunk(
  professionalActions.bookedRating,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await ProfessionalController.getBookedRating(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ratePharmacy = createAsyncThunk(
  professionalActions.rate,
  async (params: IRatePharmacyParams, { rejectWithValue }) => {
    try {
      const resp = await ProfessionalController.rate(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyRatingItems = createAsyncThunk(
  professionalActions.ratingItems,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await ProfessionalController.ratingItems(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
