import React, { useEffect, useState, FC } from 'react';

import {
  Collapse,
  Grid,
  IconButton,
  ListItemButton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import { faqSelector, getFaq, PlatformTypes } from '@pharmaplan/common';
import AdsContainer from '../common/AdsContainer';
import ResponsiveClasses from '../../theme/ResponsiveClasses';
import ContentContainer from '../common/ContentContainer';
import strings from '../../localization';
import ThemeConstants from '../../theme/ThemeConstants';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';

import styles from './styles';

const { container, collapse, title, listTitle } = styles;
const { hideOnLg } = ResponsiveClasses;
const { grey } = ThemeConstants;

interface IHelp {
  isPharmacyAdmin?: boolean;
}

const Help: FC<IHelp> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [index, setIndex] = useState<Array<number>>([]);

  const faqs = useAppSelector(faqSelector);

  const handleClick = (i: number) =>
    () => {
      if (index.includes(i)) {
        setIndex(index.filter((item) =>
          item !== i));
      } else {
        setIndex([...index, i]);
      }
    };

  const handleNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getFaq(PlatformTypes.Web));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <ContentContainer>
          <IconButton sx={styles.backButton} onClick={handleNavigation}>
            <BackIcon color="primary" />
            <Typography color="primary">{strings.back}</Typography>
          </IconButton>

          <Typography sx={title}>{strings.faq}</Typography>

          {faqs.map((item, i) => {
            const { name, answer, question } = item ?? {};

            const showQuestion = `${i + 1}. ${question}`;
            const showExpand = index.includes(i) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            );

            return (
              <Box key={name} sx={container}>
                <ListItemButton style={listTitle} onClick={handleClick(i)}>
                  {showQuestion}
                  {showExpand}
                </ListItemButton>

                <Collapse in={index.includes(i)}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: answer }}
                    variant="body1"
                    sx={collapse}
                    color={grey[800]}
                  />
                </Collapse>
              </Box>
            );
          })}
        </ContentContainer>
      </Grid>

      <Grid sx={hideOnLg} item xs={2}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default Help;
