import React, { FC, useCallback } from 'react';

import { Editor } from '@tiptap/react';
import { Paper } from '@mui/material';

import { ReactComponent as ImageIcon } from '../../../../assets/svg/image-add-line.svg';
import useEditorToolbar from '../../../../hooks/Editor/useEditorToolbar';
import genericClasses from '../../../../theme/GenericClasses';

import EditorToolbarItems from './EditorToolbarItems/EditorToolbarItems';
import styles from '../styles';

interface IEditorToolbar {
  editor: Editor | null;
}

const EditorToolbar: FC<IEditorToolbar> = ({ editor }) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const { toolbarConfig, handleFileUpload } = useEditorToolbar({ editor });

  const imageIconClick = () => {
    inputFile.current?.click();
  };

  const ImageUploadIcon = useCallback(
    () =>
      (
        <>
          <ImageIcon height={20} width={20} />
          <input
            ref={inputFile}
            style={genericClasses.hidden}
            type="file"
            onChange={handleFileUpload}
          />
        </>
      ),
    [handleFileUpload, inputFile],
  );

  return (
    <Paper sx={styles.toolbarContainer} elevation={1}>
      {toolbarConfig.map((item) => {
        const { key, icon, handleClick, isActive } = item;
        return (
          <EditorToolbarItems
            Icon={icon}
            handleClick={handleClick}
            key={key}
            isActive={!!isActive}
          />
        );
      })}
      <EditorToolbarItems
        Icon={ImageUploadIcon}
        handleClick={imageIconClick}
        isActive={false}
      />
    </Paper>
  );
};

export default EditorToolbar;
