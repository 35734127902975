import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';
import { FormikProps } from 'formik';

import { Constants } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';

import classes from './style';

interface RadioButtonItem {
  label: string | number;
  value: string | number;
}

interface IRadioButtons {
  name: string;
  radioItems: Array<RadioButtonItem>;
  formik: FormikProps<any>;
  radioLabel?: string;
  row?: boolean;
  customClass?: SxProps;
  horizontal?: boolean;
}

const defaultProps = {
  row: true,
  customClass: {},
  radioLabel: Constants.null,
};

const CustomRadioButton = ({
  name,
  radioItems,
  radioLabel,
  row,
  formik,
  customClass,
  horizontal,
}: IRadioButtons) => {
  const { formLabel, radioLabelText } = classes;
  const { hidden } = genericClasses;
  const { values, setFieldValue } = formik ?? {};

  return (
    <FormControl
      sx={[
        customClass as SystemStyleObject,
        horizontal ? classes.horizontal : {},
      ]}
    >
      <FormLabel sx={[radioLabel ? {} : hidden, formLabel]}>
        {radioLabel}
      </FormLabel>

      <RadioGroup
        row={row}
        name={name}
        value={values[name] ?? ''}
        onChange={(e) =>
          setFieldValue(name, e.target.value)}
      >
        {radioItems.map((item) => {
          const { label, value } = item;
          return (
            <FormControlLabel
              sx={radioLabelText}
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioButton;
