import { createReducer } from '@reduxjs/toolkit';

import {
  IPharmacyRating,
  IPharmacyRatings,
  IProfessionalBookedRatingDetails,
  IProfessionalRate,
} from '../models/ProfessionalModels';
import {
  getPharmacyBookedRating,
  getPharmacyRating,
  getPharmacyRatingItems,
} from '../actions';

export interface IProfessionalState {
  ratingItems: IPharmacyRatings;
  rate: IProfessionalRate;
  rating: IPharmacyRating;
  bookedRating: IProfessionalBookedRatingDetails;
}

const initialState: IProfessionalState = {
  ratingItems: {
    favorite: false,
    displayDetailForm: false,
    pharmacyRatingItems: [],
    pharmacyRatingQuestions: [],
  },
  rate: {
    bookingId: '',
    levels: [],
    favorite: false,
  },
  rating: {
    isFavorite: false,
    name: '',
    pharmacyId: '',
    reviews: 0,
    totalRating: '',
    ratings: [],
  },
  bookedRating: {
    data: {
      pharmacyRatings: [],
      pharmacyRatingAnswers: [],
    },
    comment: '',
    firstName: '',
    lastName: '',
    pharmacyId: '',
    isFavorite: false,
  },
};

const professionalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPharmacyRatingItems.fulfilled, (state, action) => {
      state.ratingItems = action.payload;
    })
    .addCase(getPharmacyRating.fulfilled, (state, action) => {
      state.rating = action.payload;
    })
    .addCase(getPharmacyBookedRating.fulfilled, (state, action) => {
      state.bookedRating = action.payload;
    });
});

export default professionalReducer;
