import React, { useEffect } from 'react';
import { ICalendarEventType, IRatingItems, IRatingsData } from '@pharmaplan/common';
import { OtherScreens, ScreenTypes } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import {
  IFromScreen,
  renderScreen,
  setBack,
  setFooterShow,
} from '../../../../actions/drawerActions';
import ServiceCard from '../../../SelfService/ServiceCard/ServiceCard';
import ViewPharmacistRatingContent from './ViewPharmacistContent/ViewPharmacistRatingContent';
import ViewPharmacistRatingHeader from './ViewPharmacistContent/ViewPharmacistRatingHeader/ViewPharmacistRatingHeader';
import useViewRating from '../../../../hooks/Rating/useViewRating';

import styles from './style';

interface IViewPharmacistRatingProps {
  pharmacistId: string;
  type?: ICalendarEventType;
  id?: string;
  date: string;
  eventIds: Array<string>;
  workshiftId: string;
  fromScreen?: IFromScreen;
  back?: () => void;
}

const ViewPharmacistRating = ({
  pharmacistId,
  id,
  type,
  date,
  eventIds,
  workshiftId,
  fromScreen,
  back,
}: IViewPharmacistRatingProps) => {
  const dispatch = useAppDispatch();
  const isFromReplacement = fromScreen === OtherScreens.Replacements;
  const { ratingData, ratingDetails, successAction, idProperty, itemProperty } = useViewRating(pharmacistId);

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        eventId: id,
        screenNumber: 3,
        type,
        date,
        eventIds,
        workshiftId,
        screenType: ScreenTypes.workshiftSummary,
      }),
    );
  };

  useEffect(() => {
    if (!isFromReplacement) {
      dispatch(setBack(back ?? gotoPharmacistProfile));
    }
    dispatch(setFooterShow(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ServiceCard
        customContainerStyle={styles.container}
        content={(
          <ViewPharmacistRatingHeader
            successAction={successAction}
            ratingDetails={ratingDetails}
          />
        )}
      />
      <ServiceCard
        content={(
          <ViewPharmacistRatingContent
            ratingData={ratingData}
            ratingDetails={ratingDetails}
            idProperty={idProperty as keyof IRatingsData}
            itemProperty={itemProperty as keyof Omit<IRatingItems, 'favorite'>}
          />
        )}
      />
    </>
  );
};

export default ViewPharmacistRating;
