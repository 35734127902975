import {
  ICreateWorkshiftParams,
  IPharmacyReportParams,
  IRateLevels,
  IUpdateTimingsParams,
  IReportIssue,
  IPharmacyRatesProfessional,
} from '../models/PharmacyModels';
import httpClient from './httpClient';
import { IBookedPharmacistDetails } from './types';
import { ITEMS_PER_PAGE, TypeOfUser } from '../helpers/Constants';
import Routes from '../helpers/Routes';
import { ICancelBooking } from '../models';
import { IGenericPagingApiParams, IReplacementsParams } from '../models/Models';
console.log('Loaded Self');

class PharmacyController {
  basePath: string;
  constructor() {
    this.basePath = Routes.pharmacy.basePath;
  }

  pharmacyCalendar = (params: { startDate: string; endDate: string }) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.calendar}`, {
      criteria: params,
    });

  createWorkshift = (params: ICreateWorkshiftParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.workshifts}`, {
      multipleWorkshiftModel: params,
    });

  updateWorkshift = (params: ICreateWorkshiftParams) =>
    httpClient.put(`${this.basePath}${Routes.pharmacy.workshifts}`, {
      multipleWorkshiftModel: params,
    });

  deleteWorkshift = (params: { id?: string; recurrenceId?: string }) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.workshifts}/delete`, {
      workshiftId: params.id,
      recurrenceId: params.recurrenceId || null,
    });

  workshift = (workshiftId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacy.workshifts}/${workshiftId}`
    );

  reports = (params: IPharmacyReportParams) =>
    httpClient.post(`${this.basePath}${Routes.general.reports}`, {
      criteria: {
        ...params,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  pharmacyReportFilter = () =>
    httpClient.get(`${this.basePath}${Routes.general.reports}`);

  replacements = (params: IReplacementsParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.replacements}`, {
      pagingModel: {
        itemPerPage: ITEMS_PER_PAGE,
        ...params,
      },
    });

  addFavourite = (id: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.favorite}`, {
      professionalId: id,
    });

  removeFavourite = (id: string) =>
    httpClient.delete(`${this.basePath}${Routes.pharmacy.favorite}/${id}`);

  history = (params: IGenericPagingApiParams) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.history}`, {
      ...params,
      paging: {
        ...params.paging,
        itemPerPage: ITEMS_PER_PAGE,
      },
    });

  notes = (pharmacistId: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.notes}/${pharmacistId}`);

  createNote = (params: { pharmacistId: string; note: string }) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.notes}`, params);

  updateNote = (params: { pharmacistNotesId: string; note: string }) =>
    httpClient.put(`${this.basePath}${Routes.pharmacy.notes}`, params);

  deleteNote = (noteId: string) =>
    httpClient.delete(`${this.basePath}${Routes.pharmacy.notes}/${noteId}`);

  reportsPdf = (params: Omit<IPharmacyReportParams, 'page'>) =>
    httpClient.post(`${this.basePath}/${params.type}${Routes.general.getPDF}`, {
      criteria: params,
    });

  requestCancellationBooking = (params: ICancelBooking) =>
    httpClient.post(`${this.basePath}${Routes.general.cancelRequest}`, params);

  replacementsPdf = () =>
    httpClient.get(
      `${this.basePath}${Routes.general.replacements}${Routes.general.getPDF}`
    );

  bookedPharmacistDetails = ({ id, eventType }: IBookedPharmacistDetails) =>
    httpClient.get(`${this.basePath}${Routes.general.get}/${eventType}/${id}`);

  bookingRequestedList = (workshiftId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacy.requestedBookings}/${workshiftId}`
    );

  approveRequest = (userType: TypeOfUser, bookingId: string) => {
    const uUser =
      userType.toLowerCase() === TypeOfUser.pharmacist.toLowerCase()
        ? 'professional'
        : userType.toLowerCase();
    console.log(uUser, 'HERE');
    return httpClient.post(
      `${uUser}${Routes.general.bookings}${Routes.pharmacy.approve}`,
      {
        bookingId,
      }
    );
  };

  ignoreRequest = (userType: TypeOfUser, bookingId: string) =>
    httpClient.post(
      `${userType.toLowerCase()}${Routes.general.bookings}${
        Routes.pharmacy.ignore
      }`,
      {
        bookingId,
      }
    );

  getHourlyRate = () =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.workshiftData}`);

  ratingItems = (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}${Routes.pharmacy.ratingItems}/${pharmacistId}`
    );

  rate = (params: IPharmacyRatesProfessional) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.rateProfessional}`, {
      model: params,
    });

  rating = (pharmacistId: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.rating}/${pharmacistId}`);

  getBookedRating = (bookingId: string) =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.rate}/${bookingId}`);

  updateTimings = (params: IUpdateTimingsParams) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.updateTime}`,
      params
    );

  reportIssue = (params: IReportIssue) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.reportIssue}`, {
      bookingId: params.bookingId,
      issueType: params.issueType,
      description: params.description,
    });

  getReportIssue = () =>
    httpClient.get(`${this.basePath}${Routes.pharmacy.reportIssue}`);

  selfPharmacyCancelBooking = (bookingId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.general.cancel}`,
      { bookingId }
    );

  doesPharmacyExist = (name: string) =>
    httpClient.post(`${this.basePath}${Routes.general.exists}`, {
      name,
    });

  addIncompatible = (pharmacistId: string) =>
    httpClient.post(`${this.basePath}${Routes.pharmacy.incompatibleMatches}`, {
      professionalId: pharmacistId,
    });

  removeIncompatible = (pharmacistId: string) =>
    httpClient.delete(
      `${this.basePath}${Routes.pharmacy.incompatibleMatches}/${pharmacistId}`
    );

  pharmacyPreApprove = (bookingId: string) =>
    httpClient.post(
      `${this.basePath}${Routes.general.bookings}${Routes.pharmacy.preApprove}`,
      { bookingId }
    );

  pharmacistReplacementDetails = (pharmacistId: string) =>
    httpClient.get(
      `${this.basePath}/${Routes.pharmacist.pharmacistBasePath}/${pharmacistId}`
    );
}

export default new PharmacyController();
