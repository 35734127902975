import React, { FC, FunctionComponent, SVGProps } from 'react';

import { Button } from '@mui/material';

import styles from './styles';

interface IEditorToolbarItems {
  Icon:
    | (() => JSX.Element)
    | FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  handleClick: () => void;
  isActive: boolean;
}

const EditorToolbarItems: FC<IEditorToolbarItems> = ({
  Icon,
  handleClick,
  isActive,
}) =>
  (
    <Button
      sx={[styles.buttonContainer, isActive ? styles.active : {}]}
      onClick={handleClick}
    >
      <Icon height={20} width={20} />
    </Button>
  );

export default EditorToolbarItems;
