import { DateTime } from 'luxon';

import {
  AdminAllowanceNames,
  AdminLegendVariant,
  IAdminBookedPharmacistDetails,
  IAdminWorkshiftDetails,
} from '@pharmaplan/common';

import { idStore } from '@pharmaplan/common/helpers/ServiceHandler';
import { toJSDate } from '../../../components/Dashboard/MainCalendar/helpers';
import {
  decimalConverterProxy,
  handlerForZeroes,
} from '../../../helpers/proxyHandlers';

import useExpressBookingTypes from './useExpressBookingTypes';

const { posted } = AdminLegendVariant;
const {
  emergencyFees,
  mealAllowance,
  travelAllowance,
  pharmacyHourlyRate,
  accommodationAllowance,
  pharmacistHourlyRate,
} = AdminAllowanceNames;

const useExpressBookingFormik = (
  type: Exclude<AdminLegendVariant, 'total'>,
  expressValues: Array<string>,
  id?: string,
) => {
  const { detailsApiMap } = useExpressBookingTypes();
  const { details } = detailsApiMap[type];

  const isPosted = type === posted;

  const getFormikInitialValues = () => {
    if (id) {
      const {
        pharmacyName,
        startDate,
        endDate,
        pharmacyId,
        notes,
        pharmacistHourlyRate: formikPharmacistRate,
        pharmacyHourlyRate: formikPharmacyRate,
        emergencyFees: formikEmergencyFees,
        mealAllowance: formikMealAllowance,
        travelAllowance: formikTravelAllowance,
        accommodationAllowance: formikAccommodationAllowance,
      } = (details as unknown as IAdminWorkshiftDetails) ?? {};

      const { firstName, lastName } = (details as IAdminBookedPharmacistDetails) ?? {};
      const clientId = details[idStore.id as keyof typeof details];
      const name = firstName ? `${firstName} ${lastName}` : '';

      const allowances = {
        [emergencyFees]: formikEmergencyFees,
        [mealAllowance]: formikMealAllowance,
        [travelAllowance]: formikTravelAllowance,
        [accommodationAllowance]: formikAccommodationAllowance,
        [pharmacistHourlyRate]: formikPharmacistRate,
        [pharmacyHourlyRate]: formikPharmacyRate,
      };

      const handledForZeros = new Proxy(allowances, handlerForZeroes);
      const handledForDecimals = new Proxy(
        handledForZeros,
        decimalConverterProxy,
      );

      return {
        selectedDate: DateTime.fromISO(startDate, { setZone: true }),
        pharmacies: '',
        pharmacists: name,
        pharmacySelected: {
          label: pharmacyName ?? '',
          id: pharmacyId ?? '',
          subtitle: '',
        },
        pharmacistSelected: isPosted
          ? null
          : {
            label: name,
            id: clientId,
            subtitle: '',
          },
        pharmacyId,
        pharmacistId: clientId,
        startTime: toJSDate(startDate),
        endTime: toJSDate(endDate),
        pharmacistHourlyRate,
        notes: notes ?? '',
        ...handledForDecimals,
      };
    }

    return {
      selectedDate: '',
      pharmacies: '',
      pharmacists: '',
      pharmacyId: '',
      pharmacistId: '',
      pharmacistSelected: null,
      pharmacySelected: null,
      startTime: null,
      endTime: null,
      [AdminAllowanceNames.emergencyFees]: '',
      [AdminAllowanceNames.travelAllowance]: '',
      [AdminAllowanceNames.accommodationAllowance]: '',
      [AdminAllowanceNames.mealAllowance]: '',
      [AdminAllowanceNames.pharmacistHourlyRate]: '',
      [AdminAllowanceNames.pharmacyHourlyRate]: '',
    };
  };

  return { getFormikInitialValues };
};

export default useExpressBookingFormik;
