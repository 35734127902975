/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect } from 'react';

import { Avatar } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import strings from '../../../../localization';

import UploadDialog from '../UploadDialog';
import styles from './style';

const filesTypes = '.png, .jpg, .jpeg';

interface IUploadImageBase {
  onSubmit?: (values: FormikValues) => void;
}

const stub = () => {};

const UploadImageBase: FC<IUploadImageBase> = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      image: {
        name: '',
        size: null,
      },
      isPictureUploaded: false,
    },
    enableReinitialize: true,
    onSubmit: onSubmit ?? stub,
  });

  const { setFieldValue } = formik;
  const { image, isPictureUploaded } = formik.values;
  const { size } = image ?? {};
  const displayImage = size
    ? URL.createObjectURL(image as unknown as File)
    : '';

  const handleClear = () => {
    setFieldValue('image', null);
    setFieldValue('isPictureUploaded', true);
  };

  useEffect(() => {
    if (size) {
      setFieldValue('isPictureUploaded', !!size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <UploadDialog
      formik={formik}
      message={strings.sizeOfImage}
      name="image"
      fileSize={2000}
      chooseLabel={strings.uploadFile}
      errorMessage={strings.sizeOfImage}
      acceptedFileTypes={filesTypes}
      disableButton={!isPictureUploaded}
      handleClear={handleClear}
      customComponent={(
        <Avatar
          imgProps={styles.imgProp}
          src={size ? displayImage : AvatarFallback}
          sx={styles.avatar}
        />
      )}
    />
  );
};

export default UploadImageBase;
