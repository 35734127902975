import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { FormikValues } from 'formik';

import {
  adminCreateAdvertisement,
  adminGetAdvertisement,
  adminUpdateAdvertisement,
  AdPlatformTypes,
  advertisementFilters,
  advertisementSelector,
  MiscType,
  PublishTypes,
} from '@pharmaplan/common';

import { DateTime } from 'luxon';
import { BroadcastGroupActionType } from '../../../helpers/Constants';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import strings from '../../../localization';

const { create, edit } = BroadcastGroupActionType;

interface IINitialValues {
  name: string;
  platforms: Array<AdPlatformTypes | MiscType.All>;
  languageId: string;
  publishType: PublishTypes | string;
  expiry: null | string;
  navigateUrl: string;
  form: null | FormData;
  imageName: string;
  imageUrl: string;
}

const initialValues: IINitialValues = {
  name: '',
  platforms: [],
  languageId: '',
  publishType: '',
  expiry: null,
  navigateUrl: '',
  form: null,
  imageName: '',
  imageUrl: '',
};

const useCreateEditAdvertising = (formType: BroadcastGroupActionType) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(advertisementFilters);
  const { state } = useLocation();
  const { id } = state ?? {};

  const advertisement = useAppSelector(advertisementSelector);
  const {
    plaformTypes,
    languageId: adLanguageId,
    navigateUrl: adNavigateUrl,
    expiry: adExpiry,
  } = advertisement ?? {};

  const advertisementFormat = {
    ...advertisement,
    platforms:
      plaformTypes.length === filters.platforms.length
        ? [MiscType.All, ...plaformTypes]
        : plaformTypes,
    languageId: adLanguageId || MiscType.All,
    navigateUrl: adNavigateUrl ?? '',
    expiry: adExpiry ? DateTime.fromISO(adExpiry) : null,
  };

  const createSubmit = (values: FormikValues) => {
    const {
      name,
      platforms,
      languageId,
      publishType,
      expiry,
      navigateUrl,
      form,
      isImageChanged,
    } = values ?? {};

    const formattedLanguageId = languageId === MiscType.All ? null : languageId;
    const formattedPlatforms = platforms
      .filter((item: AdPlatformTypes | MiscType) =>
        item !== MiscType.All)
      .join(',');

    dispatch(
      adminCreateAdvertisement({
        name,
        platforms: formattedPlatforms,
        languageId: formattedLanguageId,
        publishType,
        expiry: expiry?.toISO({ includeOffset: false }),
        url: navigateUrl,
        isImageChanged,
        form,
      }),
    );
  };

  const editSubmit = (values: FormikValues) => {
    const {
      name,
      platforms,
      languageId,
      publishType,
      expiry,
      navigateUrl,
      form,
      isImageChanged,
    } = values ?? {};

    const formattedLanguageId = languageId === MiscType.All ? null : languageId;
    const formattedPlatforms = platforms
      .filter((item: AdPlatformTypes | MiscType) =>
        item !== MiscType.All)
      .join(',');

    dispatch(
      adminUpdateAdvertisement({
        name,
        platforms: formattedPlatforms,
        languageId: formattedLanguageId,
        publishType,
        expiry: expiry?.toISO({ includeOffset: false }),
        url: navigateUrl,
        isImageChanged,
        form,
        advertisementId: id,
      }),
    );
  };

  const config = {
    [create]: {
      title: strings.createAdvertisingCampaign,
      buttonLabel: strings.create,
      submit: createSubmit,
      initialValues,
    },
    [edit]: {
      title: strings.editAdvertisingCampaign,
      buttonLabel: strings.save,
      submit: editSubmit,
      initialValues: advertisementFormat,
    },
  };

  useEffect(() => {
    if (id) {
      dispatch(adminGetAdvertisement(id));
    }
  }, [id]);

  return {
    title: config[formType].title,
    submit: config[formType].submit,
    label: config[formType].buttonLabel,
    initialValues: config[formType].initialValues,
    advertisement,
  };
};

export default useCreateEditAdvertising;
