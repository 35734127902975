import React, { useState } from 'react';

import { FormikValues } from 'formik';
import { ConfirmationTypes } from '@pharmaplan/common';
import TermsAndConditionsModal from '../../components/Modals/TermsAndConditionsModal';
import strings from '../../localization';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import classes from '../../components/Profile/ProfileForm/style';
import ProfileLocationMapView from '../../components/Profile/ProfileForm/ProfileLocationMapView';
import UploadImageBase from '../../components/common/UploadDialog/UploadImageBase';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

const { editAccountNumber } = ConfirmationTypes;

const useProfileFormDailog = (edit?: () => void) => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(true);

  const {
    customModalHeading,
    customTitleContainer,
    mapContainerSignUpFlow,
    selectContainer,
    footerContainer,
  } = classes ?? {};

  const showTermsAndConditions = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.termsAndConditionsHeader,
        },
        Component: <TermsAndConditionsModal />,
        maxWidth: 'xl',
        customStyle: {
          customModalHeading,
          customTitleContainer,
        },
        showCloseButton: true,
      }),
    );
  };

  const showPickYourLocation = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.pickYourLocationOnMap,
        },
        Component: (
          <ProfileLocationMapView
            customStyles={{
              container: mapContainerSignUpFlow,
              subContainer: selectContainer,
              footer: footerContainer,
            }}
            isSignup
          />
        ),
        maxWidth: 'lg',
        customStyle: { customModalHeading },
        showCloseButton: true,
      }),
    );
  };

  const showPictureUploadModal = (onSubmit?: (values: FormikValues) => void) =>
    () => {
      dispatch(
        setDialog({
          maxWidth: 'xs',
          showCloseButton: true,
          Component: <UploadImageBase onSubmit={onSubmit} />,
          heading: {
            title: strings.uploadPicture,
          },
        }),
      );
    };

  const handleEdit = () => {
    setDisabled(false);
    dispatch(resetDialog());
    edit?.();
  };

  const showEditAccount = () => {
    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={editAccountNumber}
            confirmAction={handleEdit}
          />
        ),
        showCloseButton: false,
        heading: { title: '' },
      }),
    );
  };
  return {
    showTermsAndConditions,
    showPickYourLocation,
    showPictureUploadModal,
    showEditAccount,
    disabled,
  };
};

export default useProfileFormDailog;
