export default {
  workshiftType: 'Shifts Type',
  workshift: 'Shift',
  workshifts: 'Shifts',
  pageWorkshift: 'Shifts',
  postedWorkshift: '{0} posted shift',
  availableWorkshifts: 'Available Shifts',
  availableWorkshiftsMobile: 'Available Shifts',
  requestedWorkshifts: 'Requested Shifts',
  workshiftReport: 'Shifts Report',
  workshiftReportMobile: 'Shifts Report',
  postedWorkshiftReport: 'Posted Shifts Report',
  addWorkshifts: 'Add Shift',
  updateWorkshift: 'Update Shift',
  deleteWorkshift: 'Delete Shift',
  noWorkshifts: 'No Shifts!',
  cancelBookedWorkshift: 'Cancel Booked Shift',
  cancelWorkshift: 'Cancel Requested Shift',
  workshiftDetails: 'Shift Details',
  manageWorkshifts: 'Manage Shifts',
  updatePastWorkshiftError: 'Past Shifts cannot be edited!',
  replacementWorkshifTitle: 'Replacement Shift on {0}',
  singlePostedWorkshift: 'Posted Shift',
  dashboardBtn:
    'The “Dashboard” is your main interface that displays your shifts information in a Month, Week or Day View.',
  monthView:
    'The “Month View” shows you shifts information of a specific month.',
  weekView: 'The “Week View” shows you shifts information of a specific week.',
  dayView: 'The “Day View” shows you shifts information of a specific day.',
  searchBtn:
    'The “Search” button allows you to filter shifts in the current View.',
  mapView:
    'The “Map View” allows you to visualize the geographical location of available, requested and booked shifts. You can switch back to the “Calendar View” by selecting the "',
  mapViewMobileWalkthrough:
    'The “Map View” allows you to visualize the geographical location of pharmacies posting shifts. You can switch back to the “Calendar View” by selecting the “Calendar View” icon.',
  myWorkShifts:
    'The “My Shifts Management” button allows you to manage your shifts. Click on the “+” button to create single or recurrent shifts.',
  filterBtn:
    'The “Filter” button allows you to filter shifts by type in the current View.',
  watchlistIcon:
    'The “Watchlist” icon shows shifts you placed on your watch list for future considerations.',
  workshiftDate: 'Shift: {0} ',
  viewPostedWorkshiftsMobile: 'View Posted Shifts ({0})',
  workshiftPosted: 'Shift Posted',
  workshiftRequested: 'Shift Requested',
  createWorkshift: 'Create Shift',
  workshiftBooked: 'Shift Booked',
  availableWorkshift: 'Available Shift',
  requestedWorkshift: 'Requested Shift',
  bookedWorkshift: 'Booked Shift',
  bookedWorkshifts: 'Booked Shifts',
  workshiftHasAConfirmed:
    'Shift has a confirmed Booking. Are you sure you want to update?',
  shiftDetails: 'Shift Details',
  confirmDeleteWorkshift: 'Are you sure you want to delete this shift?',
  requestSuccess: 'The shift request has been submitted successfully.',
  ratingSuccessful: 'Rating has been submitted successfully!',
  expiredError: 'The shift you are trying to access has expired.',
  deleteConfirmationPharmacy:
    'All your upcoming shift postings/ requests would get deleted, are you sure you want to proceed with deletion?',
  totalWorkshifts: 'Total Shifts',
  bookedWorkshiftDetails: 'Booked Shift Details',
  availableWorkshiftFormatted: 'Available Shifts: {0}',
  workshiftWithDate: 'Shift Date: {0}',
  workshiftDateText: 'Shift Date',
  postedWorkshiftsReport: 'Posted Shifts Report',
  editWorkshift: 'Edit Shift',
  minHoursWorkshift: 'Min Hours Shift',
  ignoreWorkshift: 'Ignore Shift!',
  matchingAvailabilitiesOfWorkshift: 'Matching Availabilities of Shift',
  requestedWorkshiftsFormatted: 'Requested Shifts: {0}',
  bookedWorkshiftsFormatted: 'Booked Shifts: {0}',
  workshiftCode: 'Shift Code',
  findMatchingWorkshifts: 'Find Matching Shifts',
  matchingWorkshiftsOfAvailability: 'Matching Shifts of Availability',
  noMatchingWorkshiftsToShow: 'No matching shifts to show',
  areYouSureRequestWorkshift: 'Are you sure you want to request this shift?',
  listOfWorkshifts: 'List of Shifts',
  incompatiblePharmacistDescription:
    "Pharmacists that are mapped incompatible to this pharmacy will not be able to view this pharmacy's posted shifts.",
  workshiftCreatedSuccessfully: 'Shift created successfully',
  workshiftDeletedSuccessfully: 'Shift deleted successfully',
  confirmWorkshiftCancel: 'Are you sure you want to cancel the shift?',
  upcomingWorkshifts: 'Upcoming Shifts',
  pastWorkshifts: 'Past Shifts',
  workshiftLogs: 'Shift Logs',
  areYouSureRemoveWatchlist:
    'Are you sure you want to remove this shift from watchlist?',
  watchlistedPharmacyWorkshifts: 'Watchlisted Pharmacy Shifts',
  confirmDeleteWorkshifts: 'Are you sure you want to delete these shifts?',
  confirmDeleteCountWorkshift:
    'Are you sure you want to delete the {0} selected shifts?',
  vacationRequestedConflict:
    'There is already a requested shift on this day. If you continue, your booking request will be canceled. Are you sure you want to continue?',
  multipleVacationRequestedConflict:
    'There are already requested shifts on some of the days. If you continue, your booking requests will be canceled. Are you sure you want to continue?',
  adminVacationRequestedConflict:
    "There are already requested shifts on some of the days. If you continue, the pharmacist's booking requests will be canceled. Are you sure you want to proceed?",
  workshiftRequestConflict:
    'A shift request exists for these days. Creating a vacation cancels it. Do you wish to continue?',
  workshiftBookedConflict:
    'A shift is booked for these days. Sorry, this action cannot be performed.',
  availabilityAlreadyRequested:
    'The availability you are trying to delete is already requested for another shift. Are you sure you want to proceed?',
  workshiftsStatistics: 'Shifts Statistics',
  workshiftTypes: 'Shifts (Posted, Requested, and Booked)',
  workshiftPostings: 'Shift Postings',
  ratingAPharmacistFormat:
    'Rating a pharmacist is only open for {0} days after the shift.',
  noWorkshiftsFound: 'No shifts found!',
};
