/* eslint-disable react/no-danger */
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import {
  getTermsAndConditions,
  istermsAgreed,
  termsAndConditionsHTMLTemplate,
} from '@pharmaplan/common';
import { DialogContent } from '@mui/material';
import classes from './styles';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import CustomDialogActions from '../../common/CustomModal/ModalCommon/CustomDialogActions/CustomDialogActions';
import strings from '../../../localization';
import { resetDialog } from '../../../reducers/dialogReducer';

const TermsAndConditionsModal: React.FC<{ isCancellation?: boolean }> = ({
  isCancellation,
}) => {
  const dispatch = useAppDispatch();
  const termsAndConditionsHTML = useAppSelector(termsAndConditionsHTMLTemplate);
  const [disable, setDisable] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getTermsAndConditions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = () => {
    const isScrolledToEnd = (ref.current?.offsetHeight ?? 0) + (ref.current?.scrollTop ?? 0)
      >= (ref.current?.scrollHeight ?? 0);

    if (isScrolledToEnd) {
      setDisable(false);
    }
  };

  return (
    <>
      <DialogContent ref={ref} onScroll={onScroll}>
        <Box sx={classes.container} px={5}>
          <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHTML }} />
        </Box>
      </DialogContent>
      {!isCancellation && (
        <Box sx={classes.footerContainer}>
          <CustomDialogActions
            primaryBtnTitle={strings.agree}
            primaryFunction={() => {
              dispatch(istermsAgreed(true));
              dispatch(resetDialog());
            }}
            primaryDisabled={disable}
            secondaryFunction={() => {
              dispatch(istermsAgreed(false));
              dispatch(resetDialog());
            }}
            secondaryBtnTitle={strings.cancel}
          />
        </Box>
      )}
    </>
  );
};

export default TermsAndConditionsModal;
