import {
  adminPharmacyRatingDetails,
  adminPharmacyRatingItems,
  adminPharmacyRatings,
  adminProfessionalDetails,
  adminProfessionalRatingDetails,
  adminProfessionalRatingItems,
  adminProfessionalRatings,
  getAdminPharmacistRating,
  getAdminPharmacyRating,
  getAdminPharmacyRatingItems,
  getAdminProfessionalRatingItems,
  getPharmacistDetails,
  getPharmacyDetails,
  getPharmacyRatingDetails,
  getRatingDetails,
  pharmacyDetails,
  TypeOfUser,
} from '@pharmaplan/common';
import { useAppSelector } from '../../useAppSelector';

const { pharmacy } = TypeOfUser;

const useAdminRatings = (userType: TypeOfUser) => {
  const isPharmacy = userType === pharmacy;

  const professionalRatingDetails = useAppSelector(adminProfessionalRatings);
  const professionalRatingData = useAppSelector(adminProfessionalRatingItems);
  const professionalDetailedRating = useAppSelector(
    adminProfessionalRatingDetails,
  );
  const professionalUserDetails = useAppSelector(adminProfessionalDetails);

  const pharmacyRatingDetails = useAppSelector(adminPharmacyRatings);
  const pharmacyRatingData = useAppSelector(adminPharmacyRatingItems);
  const pharmacyDetailedRating = useAppSelector(adminPharmacyRatingDetails);
  const pharmacyUserDetails = useAppSelector(pharmacyDetails);

  const { professionalRatingItems, professionalRatingQuestions } = professionalRatingData ?? {};
  const { pharmacyRatingItems, pharmacyRatingQuestions } = pharmacyRatingData ?? {};

  const config = () => {
    if (isPharmacy) {
      return {
        userRatingItems: pharmacyRatingItems,
        ratingData: pharmacyRatingData,
        propertyId: 'pharmacyRatingItemId',
        itemId: 'pharmacyItemId',
        details: pharmacyRatingDetails,
        nameProperty: 'professionalName',
        ratingDetailsAPI: getPharmacyRatingDetails,
        userDetailsAPI: getPharmacyDetails,
        ratingAPI: getAdminPharmacyRating,
        ratingItemAPI: getAdminPharmacyRatingItems,
        questionId: 'pharmacyRatingQuestionId',
        questions: pharmacyRatingQuestions,
        detailedRating: pharmacyDetailedRating,
        user: pharmacyUserDetails?.pharmacy,
        codeProperty: 'professionalCode',
      };
    }
    return {
      userRatingItems: professionalRatingItems,
      ratingData: professionalRatingData,
      propertyId: 'professionalRatingItemId',
      itemId: 'professionalItemId',
      details: professionalRatingDetails,
      nameProperty: 'pharmacyName',
      ratingDetailsAPI: getRatingDetails,
      userDetailsAPI: getPharmacistDetails,
      ratingAPI: getAdminPharmacistRating,
      ratingItemAPI: getAdminProfessionalRatingItems,
      questionId: 'professionalRatingQuestionId',
      questions: professionalRatingQuestions,
      detailedRating: professionalDetailedRating,
      user: professionalUserDetails?.professional,
      codeProperty: 'pharmacyCode',
    };
  };

  return config();
};

export default useAdminRatings;
