import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

import {
  AllowanceEnums,
  AllowancesNames,
  RecurrenceActions,
  TypeOfUser,
  getRecurrence,
  pharmacyWorkshift,
  recurrenceType,
  updateWorkshift,
} from '@pharmaplan/common';

import { selectFooterPaginationCurrent } from '../../../../selectors/drawerSelector';
import useUpdateWorkshift from '../../../../hooks/workshift/useUpdateWorkshift';
import AddUpdateEvents from '../../../common/AddUpdateEvents/AddUpdateEvents';
import { InvertedRepeatMap, isIpad } from '../../../../helpers/Constants';
import { toJSDate } from '../../../Dashboard/MainCalendar/helpers';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import CustomTextField from '../../../common/CustomTextField';
import genericClasses from '../../../../theme/GenericClasses';
import {
  makeReqParams,
  setHour,
} from '../../../../helpers/workshift/functions';
import strings from '../../../../localization';
import {
  decimalConverterProxy,
  handlerForZeroes,
} from '../../../../helpers/proxyHandlers';

import AllowanceWorkshift from '../AllowanceWorkshift';
import Validator from '../Validator';
import classes from './styles';

const { meal, travel, accommodation, emergency } = AllowanceEnums;
const { accommodationAllowanceType } = AllowancesNames;
interface IUpdate {
  eventIds: Array<string>;
  recurrenceId?: string;
  recType?: RecurrenceActions;
}

const { Never } = recurrenceType;
const { pharmacist } = TypeOfUser;
const { series } = RecurrenceActions;

const Update: FC<IUpdate> = ({ eventIds, recurrenceId, recType }) => {
  const dispatch = useAppDispatch();

  const current = useAppSelector(selectFooterPaginationCurrent);
  const currentWorkshift = useAppSelector(pharmacyWorkshift);

  const {
    recurrence,
    paidHourlyRate,
    notes,
    endDate,
    startDate,
    travelAllowanceRatePerKm,
    travelAllowanceAmount,
    mealAllowance,
    travelAllowanceTypes,
    emergencyFees: currentEmergency,
    accommodationAllowance,
    accommodationAllowanceType: accommodationType,
  } = currentWorkshift ?? {};

  const { recurrenceType: currentRecurrenceType, endDate: recurrenceEndDate } = recurrence ?? {};

  const recurrenceSelector = useAppSelector(getRecurrence);

  const { repeatEvery } = recurrenceSelector ?? {};

  const id = eventIds[current - 1];

  const isSeries = recType === series;

  const { container } = classes;
  const { borderNoneTextField } = genericClasses;
  const valuesToParse = {
    travelAllowanceRatePerKm,
    mealAllowance,
    currentEmergency,
    accommodationAllowance,
    travelAllowanceAmount,
    paidHourlyRate,
  };

  const valuesHandledForZero = new Proxy(valuesToParse, handlerForZeroes);
  const valuesHandledForDecimals = new Proxy(
    valuesHandledForZero,
    decimalConverterProxy,
  );

  const handleSubmit = (values: FormikValues) => {
    // NOTE: THE ORDER MATTERS
    dispatch(
      updateWorkshift({
        ...makeReqParams(values, recurrenceSelector),
        recurrenceId: isSeries ? recurrenceId ?? '' : null,
        workshiftId: id,
      }),
    );
  };

  const recurrenceTypeForWorkshift = isSeries
    ? currentRecurrenceType ?? Never
    : repeatEvery;

  const formik = useFormik({
    initialValues: {
      ...currentWorkshift,
      ...valuesHandledForDecimals,
      [accommodationAllowanceType]: `${accommodationType}`,
      selectedDate: DateTime.fromISO(startDate, {
        setZone: true,
      }),
      allDay: false,
      endDate: recurrenceEndDate || endDate,
      notes,
      startTime: toJSDate(startDate),
      endTime: toJSDate(endDate),
      replacement: pharmacist,
      recurrence: InvertedRepeatMap[recurrenceTypeForWorkshift],
    },
    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit: handleSubmit,
  });

  useUpdateWorkshift(eventIds, formik, recurrenceId, recType);

  const { values, setFieldValue } = formik ?? {};
  const { selectedDate } = values ?? {};

  const setUserWorkTimings = () => {
    setFieldValue('startTime', setHour(selectedDate, 8));
    setFieldValue('endTime', setHour(selectedDate, 22));
  };

  const notesSx = isIpad ? classes.notes : {};

  useEffect(() => {
    formik.setFieldValue(meal, !!mealAllowance);
    formik.setFieldValue(travel, !!travelAllowanceTypes?.length);
    formik.setFieldValue(accommodation, !!accommodationAllowanceType);
    formik.setFieldValue(emergency, !!currentEmergency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkshift]);

  return (
    <Box sx={container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        isSeries={isSeries}
        isUpdate
      />

      <AllowanceWorkshift formik={formik} />

      <Box sx={notesSx}>
        <CustomTextField
          variant="outlined"
          formik={formik}
          id="notes"
          placeholder=" "
          label={strings.notes}
          name="notes"
          multiline
          maxLength={200}
          customClass={borderNoneTextField}
        />
      </Box>
    </Box>
  );
};

export default Update;
