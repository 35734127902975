export default {
  container: {
    backgroundColor: 'white',
    maxWidth: 781,
    width: '100%',
    padding: 5,
    height: 'auto',
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    padding: 2,
  },
  buttons: {
    padding: 2,
  },
  back: { color: 'common.gray' },
  backText: {
    fontWeight: '500',
    fontSize: 16,
    ml: 1,
  },
};
