import ThemeConstants from '../../../theme/ThemeConstants';

const classes = (state: boolean) =>
  ({
    ratingContainer: {
      marginInline: '30px',
    },
    ratingTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      marginTop: '8px',
      marginBottom: '40px',
    },
    closeContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    closeIcon: {
      color: 'common.grey350',
      marginBlock: '24px 10px',
      backgroundColor: 'common.lightBackgroundColor',
      padding: '3px',
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
    },
    isfavoriteButton: {
      maxHeight: '30px',
      color: state ? '#4A5056' : '#96A0BC',
      border: `1px solid ${state ? '#4A5056' : '#96A0BC'}`,
      '&:hover': {
        color: state ? '#4A5056' : '#96A0BC',
        border: `1px solid ${state ? '#4A5056' : '#96A0BC'}`,
      },
    },
    buttonIcon: { width: '24px', height: '24px' },
    error: {
      fontSize: 12,
      color: ThemeConstants.common.error,
    },
  });

export default classes;
