/* eslint-disable no-restricted-globals */
import React from 'react';

import { TypeOfSummary, convertToDecimals, currency } from '@pharmaplan/common';
import { ReactComponent as GreenTick } from '@pharmaplan/common/assets/icons/greenTick.svg';
import { ReactComponent as CrossIcon } from '@pharmaplan/common/assets/icons/crossIcon.svg';

import strings from '../../../localization';

type IValue = string | number | null | false | undefined;

const { pharmacist, workshiftDetails } = TypeOfSummary;

export const displayIcon = (isAvailable: boolean, value: IValue) => {
  if (isAvailable) return { icon: <GreenTick />, data: value };
  return { icon: <CrossIcon />, data: strings.notCovered };
};

export const handleValues = (values: Array<IValue>) => {
  const res = values.reduce(
    (acc, curr) => {
      if (!curr) {
        return acc;
      }

      if (!isNaN(curr as number)) {
        acc.sum += parseFloat(curr as string);
      } else {
        const conjunction = acc.additional.length > 0 || acc.sum > 0 ? '+' : '';
        acc.additional += `${conjunction} ${curr}`;
      }

      return acc;
    },
    { sum: 0, additional: '' },
  );

  if (!res.sum) {
    return res.additional;
  }

  return `${currency}${convertToDecimals(res.sum)} ${res.additional}`;
};

export const title = (summaryType: TypeOfSummary | undefined) => {
  switch (summaryType) {
    case pharmacist:
      return strings.pharmacist;
    case workshiftDetails:
      return strings.workshiftDetails;
    default:
      return strings.pharmacist;
  }
};
