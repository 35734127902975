import { useEffect } from 'react';
import {
  getPharmacyRating,
  getPharmacyRatingItems,
  getRating,
  getRatingItems,
  getTypeOfUser,
  pharmacyActions,
  pharmacyRating,
  pharmacyRatingItems,
  professionalActions,
  rating,
  ratingItems,
  TypeOfUser,
} from '@pharmaplan/common';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';

const { pharmacist, pharmacy } = TypeOfUser;

const useViewRating = (id: string) => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);

  const professionalRatingDetails = useAppSelector(rating);
  const professionalRatingData = useAppSelector(ratingItems);

  const pharmacyRatingDetails = useAppSelector(pharmacyRating);
  const pharmacyRatingData = useAppSelector(pharmacyRatingItems);

  const config = {
    [pharmacist]: {
      ratingDetails: pharmacyRatingDetails,
      ratingData: pharmacyRatingData,
      getRatingItemsApi: getPharmacyRatingItems,
      getRatingApi: getPharmacyRating,
      successAction: professionalActions.rating,
      idProperty: 'pharmacyRatingItemId',
      itemProperty: 'pharmacyRatingItems',
    },
    [pharmacy]: {
      ratingDetails: professionalRatingDetails,
      ratingData: professionalRatingData,
      getRatingItemsApi: getRatingItems,
      getRatingApi: getRating,
      successAction: pharmacyActions.rating,
      idProperty: 'professionalRatingItemId',
      itemProperty: 'professionalRatingItems',
    },
  };

  const {
    ratingData,
    ratingDetails,
    getRatingApi,
    getRatingItemsApi,
    successAction,
    idProperty,
    itemProperty,
  } = config[userType as keyof typeof config];

  useEffect(() => {
    dispatch(getRatingApi(id));
    dispatch(getRatingItemsApi(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ratingData, ratingDetails, successAction, idProperty, itemProperty };
};

export default useViewRating;
