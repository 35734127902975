import React, { FC, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from 'prosemirror-state';

import { EditorContent, useEditor } from '@tiptap/react';
import ImageResize from 'tiptap-extension-resize-image';
import ImageExt from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';

import useEditorUtility from '../../../hooks/Editor/useEditorUtility';

import EditorToolbar from './EditorToolbar';
import styles from './styles';
import './style.css';

const extensions = [
  StarterKit,
  ImageExt.configure({
    inline: true,
  }).extend({
    addProseMirrorPlugins() {
      return [
        new Plugin({
          props: {
            handleDOMEvents: {
              paste(view, event) {
                const hasFiles = event?.clipboardData?.files?.length;

                if (!hasFiles) {
                  return;
                }

                const images = Array.from(event.clipboardData.files).filter(
                  (file) =>
                    /image/i.test(file.type),
                );

                if (images.length === 0) {
                  return;
                }

                event.preventDefault();

                const { schema } = view.state;

                images.forEach((image) => {
                  const reader = new FileReader();

                  reader.onload = (readerEvent) => {
                    const node = schema.nodes.image.create({
                      src: readerEvent?.target?.result,
                    });
                    const transaction = view.state.tr.replaceSelectionWith(node);
                    view.dispatch(transaction);
                  };
                  reader.readAsDataURL(image);
                });
              },
            },
          },
        }),
      ];
    },
  }),
  ImageResize.configure({ inline: true }),
];

interface IEmailEditor {
  formik: FormikProps<any>;
  name: string;
  previousContent: string;
}

const EmailEditor: FC<IEmailEditor> = ({ formik, name, previousContent }) => {
  const { handleDrop } = useEditorUtility();
  const [focus, setFocus] = useState(false);
  const editor = useEditor({
    extensions,
    content: formik.values[name],
    editorProps: {
      handleDrop,
    },
  });

  useEffect(() => {
    formik.setFieldValue(name, editor?.getHTML());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor?.getHTML()]);

  useEffect(() => {
    editor?.commands.setContent(previousContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousContent]);

  const handleFocus = (focused: boolean) =>
    () => {
      setFocus(focused);
    };

  return (
    <Box sx={[styles.active, focus && styles.focusedStyle]}>
      <EditorToolbar editor={editor} />
      <EditorContent
        style={styles.editor}
        editor={editor}
        rows={5}
        height={200}
        onFocus={handleFocus(true)}
        onBlur={handleFocus(false)}
      />
    </Box>
  );
};

export default EmailEditor;
