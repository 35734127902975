import { SxProps, Theme } from '@mui/system';

const styles = {
  root: {
    padding: '32px',
    border: '1px solid',
    borderColor: 'common.border',
    borderRadius: '8px',
    maxWidth: '413px',
    maxHeight: '500',
    minHeight: '500px',
    backgroundColor: 'common.lightBackgroundColor',
  },

  name: {
    fontSize: 20,
    fontWeight: 600,
    color: '#161C2C',
    lineHeight: '26px',
  },

  code: {
    fontSize: 14,
    color: 'common.grey850',
    lineHeight: '21px',
  },

  date: {
    fontSize: 12,
    fontWeight: 300,
    color: 'grey.600',
    lineHeight: '18px',
  },

  textGap: {
    mt: '4px',
  },

  icon: {
    color: 'primary.main',
  },

  ratingContainer: {
    marginTop: '32px',
    marginBottom: '28px',
  },

  feedbackTitle: {
    fontSize: 13,
    color: 'grey.600',
  },

  feedback: {
    fontSize: 12,
    color: '#161C2C',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  container: {
    display: 'flex',
  },

  avatar: {
    height: 75,
    width: 75,
    border: '1px solid',
    borderColor: 'common.border',
    marginRight: '16px',
  },

  totalRating: {
    fontSize: 12,
    color: 'grey.500',
  },

  rating: {
    fontWeight: 500,
    fontSize: 14,
    color: 'grey.800',
  },

  rateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px',
  },

  reviewContainer: {
    maxHeight: '80px',
    overflowY: 'auto',
    paddingRight: '8px',
    scrollBehavior: 'smooth',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
