import React from 'react';

import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import {
  AllowanceEnums,
  AllowancesNames,
  AllowanceTypes,
  currency,
  workshiftData,
} from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import CustomTextField from '../../../common/CustomTextField/CustomTextField';
import CustomDropdown from '../../../common/CustomDropdown/CustomDropdown';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import IDropdownObject from '../../../../types/ICustomDropdown';
import CustomSwitch from '../../../common/CustomSwitch';
import strings from '../../../../localization';

import AllowanceSubComponent from './AllowanceSubComponent';
import { allowanceConfig } from './helper';
import classes from './style';

const { travelAllowanceType, accommodationAllowanceType, mealAllowance } = AllowancesNames;
interface IAllowanceWorkshift {
  formik: FormikProps<any>;
  isCounterOffer?: boolean;
}

const { paidHourlyRate, emergencyFees } = AllowancesNames;
const { meal, travel, accommodation, emergency } = AllowanceEnums;

const { hourlyRate, title } = classes;

const AllowanceWorkshift = ({
  formik,
  isCounterOffer,
}: IAllowanceWorkshift) => {
  const workshiftUtils = useAppSelector(workshiftData);
  const { travelAllowanceTypes, accommodationAllowanceTypes } = workshiftUtils ?? {};
  const secondaryInputConfig = {
    [meal]: (
      <CustomTextField
        usePreFilledColors={isCounterOffer}
        customClass={classes.horizontalAllowances}
        placeholder={' '}
        formik={formik}
        id={mealAllowance}
        name={mealAllowance}
        label={strings.amount}
        suffix={currency}
        horizontal
      />
    ),
    [travel]: (
      <>
        <CustomDropdown
          name={travelAllowanceType}
          formik={formik}
          multiple
          menuItems={
            dropdownMapper(
              travelAllowanceTypes,
              'key',
              'value',
            ) as IDropdownObject[]
          }
        />
        {formik.values[travelAllowanceType].map((item: number) =>
          (
            <AllowanceSubComponent
              type={AllowanceTypes.travel}
              subType={item}
              formik={formik}
            />
          ))}
      </>
    ),
    [accommodation]: (
      <>
        <CustomDropdown
          name={accommodationAllowanceType}
          formik={formik}
          menuItems={
            dropdownMapper(
              accommodationAllowanceTypes,
              'key',
              'value',
            ) as IDropdownObject[]
          }
        />
        <AllowanceSubComponent
          type={AllowanceTypes.accommodation}
          subType={formik.values[accommodationAllowanceType]}
          formik={formik}
        />
      </>
    ),
    [emergency]: (
      <CustomTextField
        usePreFilledColors={isCounterOffer}
        customClass={hourlyRate}
        placeholder={' '}
        formik={formik}
        suffix={currency}
        id={emergencyFees}
        name={emergencyFees}
        horizontal
        label={strings.amount}
      />
    ),
  };

  return (
    <Box>
      <Typography sx={title}>{strings.rateAndAllowances}</Typography>

      <CustomTextField
        usePreFilledColors={isCounterOffer}
        label={strings.hourlyRate}
        name={paidHourlyRate}
        formik={formik}
        placeholder=" "
        suffix="$"
        customClass={hourlyRate}
        id={paidHourlyRate}
        horizontal
      />
      <Box>
        {allowanceConfig.map((item) => {
          const { key, label } = item;
          const isSelected = formik.values?.[key];
          return (
            <Box sx={isSelected ? {} : classes.toggleContainer}>
              <CustomSwitch
                label={label}
                key={key}
                name={key}
                formik={formik}
              />
              {isSelected && secondaryInputConfig[key]}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AllowanceWorkshift;
