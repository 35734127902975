import React from 'react';
import { Box } from '@mui/system';
import sampleCheque from '../../../../assets/img/sampleCheque.png';
import strings from '../../../../localization';
import styles from '../../../SignupFlow/PharmacistSignupFlow/styles';

const ChequeComponent = () =>
  (
    <Box
      component="img"
      src={sampleCheque}
      height={141}
      width="100%"
      sx={styles.cheque}
      alt={strings.sampleCheque}
    />
  );

export default ChequeComponent;
