import Routes from '../helpers/Routes';
import { IRatePharmacyParams } from '../models';

import httpClient from './httpClient';

const { professionalBasePath, rate, rating, ratingItems } = Routes.professional;
class ProfessionalController {
  basePath: string;

  constructor() {
    this.basePath = professionalBasePath;
  }

  ratingItems = (pharmacyId: string) =>
    httpClient.get(`${this.basePath}${ratingItems}/${pharmacyId}`);

  rate = (params: IRatePharmacyParams) =>
    httpClient.post(`${this.basePath}${rate}`, { model: params });

  rating = (pharmacyId: string) =>
    httpClient.get(`${this.basePath}${rating}/${pharmacyId}`);

  getBookedRating = (bookingId: string) =>
    httpClient.get(`${this.basePath}${rate}/${bookingId}`);
}

export default new ProfessionalController();
