import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { Language, TypeOfUser, language } from '@pharmaplan/common';

import OptimizedAvatar from '../../../common/OptimizedAvatar/OptimizedAvatar';
import { ReactComponent as LocationIcon } from '../../../../assets/svg/location.svg';
import useAdminRatings from '../../../../hooks/Admin/Ratings/useAdminRatings';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useGetItems from '../../../../hooks/useGetItems';
import ThemeConstants from '../../../../theme/ThemeConstants';
import strings from '../../../../localization';

import LabelRating from './LabelRating/LabelRating';
import { infoConfig } from './helper';
import styles from './style';

interface IPharmacistRating {
  pharmacistId: string;
  user: TypeOfUser;
}

const { en } = Language;

const PharmacistRating: FC<IPharmacistRating> = ({ pharmacistId, user }) => {
  const { getPharmacistImage } = useGetItems();

  const {
    detailedRating,
    propertyId,
    userRatingItems,
    user: userDetails,
  } = useAdminRatings(user);
  const userLanguage = useAppSelector(language);

  const { email, city } = userDetails ?? {};
  const { name, totalRating, reviews, ratings } = detailedRating ?? {};
  console.log(userDetails);
  const isEN = userLanguage === en;
  const image = getPharmacistImage(pharmacistId);
  const parsedTotalRating = parseFloat(totalRating ?? '').toPrecision(2);

  const tempObj = ratings.reduce((acc: Record<string, number>, cur) => {
    acc[cur?.pharmacistRatingItemId ?? ''] = parseFloat(cur?.rating ?? 0);
    return acc;
  }, {});

  return (
    <Box>
      <Box sx={styles.pharmacistContainer}>
        <Box sx={styles.container}>
          <Box sx={styles.content}>
            <OptimizedAvatar src={image} avatarSize={75} />

            <Box sx={styles.infoContainer}>
              <Typography sx={styles.pharmacyNameText}>{name}</Typography>
              <Box sx={styles.locationContainer}>
                <LocationIcon
                  style={styles.locationIcon}
                  fill={ThemeConstants.common.gray}
                />
                <Typography sx={styles.cityText}>{city}</Typography>
              </Box>
            </Box>
            <Box component="div" sx={styles.divider} />

            <Box sx={styles.infoContainer}>
              <Typography sx={styles.cityText}>{strings.email}</Typography>
              <Typography sx={styles.contactText}>{email}</Typography>
            </Box>
          </Box>

          <Box sx={styles.content}>
            <StarIcon sx={styles.icon} />
            {infoConfig(parsedTotalRating, reviews).map((item) => {
              const { key, text, sx } = item ?? {};

              return (
                <Typography key={key} sx={sx}>
                  {text}
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.ratingContainer}>
        <Grid container xs={12} spacing={2} alignItems="center">
          {userRatingItems.map((item) => {
            const { englishName, frenchName } = item ?? {};
            const id = item[propertyId as keyof typeof item];
            const primaryText = isEN ? englishName : frenchName;
            const percentage = `${tempObj?.[id] ?? 0}%`;
            const value = tempObj?.[id] ?? 0;

            return (
              <LabelRating
                key={id}
                label={primaryText}
                value={value}
                percentage={percentage}
              />
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default PharmacistRating;
