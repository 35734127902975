import {
  AllowanceTypesToHandle,
  convertToDecimals,
  currency,
  getTypeOfUser,
  ICounterOffer,
  IPharmacistDetails,
  IPharmacistEvent,
  IWorkshift,
  TypeOfUser,
} from '@pharmaplan/common';
import useAdmin from '../useAdmin';
import strings from '../../localization';
import { useAppSelector } from '../useAppSelector';
import { handleValues } from '../../components/SelfService/PharmacistSummary/helpers';

const { pharmacy } = TypeOfUser;
const { paidAirTicket } = AllowanceTypesToHandle;

const travelAllowanceDropdown = [
  {
    key: '1',
    value: 'Paid Air Ticket',
  },
];
const accommodationAllowanceTypes = [
  {
    key: '2',
    value: 'Value/Night',
  },
];

const useBillDetails = (
  event:
    | Partial<IPharmacistDetails & IWorkshift>
    | IPharmacistEvent
    | ICounterOffer,
  percentFees?: number | null,
) => {
  const {
    travelAllowance,
    mealAllowance,
    accommodationAllowance,
    travelAllowanceTypes,
    cost,
    emergencyFees,
    accommodationAllowanceType,
  } = event ?? {};

  const {
    counterCost = 0,
    counterMealAllowance = 0,
    counterAccommodationAllowance = 0,
    counterAccommodationAllowanceType,
    counterTravelAllowanceTypes = [],
    counterTravelAllowance = 0,
  } = (event as ICounterOffer) ?? {};

  const { isAdmin } = useAdmin();
  const userType = useAppSelector(getTypeOfUser);

  const isPharmacy = userType === pharmacy;

  const pharmaPlanFees = percentFees ? (percentFees / 100) * (cost ?? 0) : null;

  const counterPharmaPlanFees = percentFees
    ? (percentFees / 100) * counterCost
    : null;

  const paidAirTicketGiven = travelAllowanceTypes?.includes(paidAirTicket)
    && travelAllowanceDropdown.find((item) =>
      item.key === paidAirTicket)?.value;

  const accommodationLabels = accommodationAllowanceTypes.find(
    (item) =>
      item.key === `${accommodationAllowanceType}`,
  )?.value;

  const counterPaidAirTicketGiven = travelAllowanceTypes
    && counterTravelAllowanceTypes?.includes(paidAirTicket)
    && travelAllowanceDropdown.find((item) =>
      item.key === paidAirTicket)?.value;

  const counterAccommodationLabels = accommodationAllowanceTypes.find(
    (item) =>
      item.key === `${counterAccommodationAllowanceType}`,
  )?.value;

  const travelDisplay = handleValues([travelAllowance, paidAirTicketGiven]);

  const accommodationDisplay = handleValues([
    accommodationAllowance,
    accommodationLabels,
  ]);

  const suffix = isPharmacy ? ` + ${strings.tax}` : '';

  const bookingDetailsConfig = [
    {
      key: '2',
      name: isAdmin ? strings.pharmacistContractValue : strings.contractValue,
      value: `$${convertToDecimals(cost)}${suffix}`,
      data: cost,
      amount: cost,
      counterOfferAmount: counterCost,
      counterOfferData: counterCost,
      counterOfferValue: `$${convertToDecimals(counterCost)}`,
      // manage this when we start working on self-service
      // customStyle: isPharmacist ? styles.contractValue : {},
    },
    {
      key: '3',
      name: strings.accomodation,
      value: accommodationDisplay,
      data: accommodationAllowanceType,
      amount: accommodationAllowance,

      // Counter offer
      counterOfferData: handleValues([
        counterAccommodationAllowance,
        counterAccommodationLabels,
      ]),
      counterOfferValue: handleValues([
        counterAccommodationAllowance,
        counterAccommodationLabels,
      ]),
      counterOfferAmount: counterAccommodationAllowance,
    },
    {
      key: '4',
      name: strings.emergencyServiceFees,
      value: `$${convertToDecimals(emergencyFees)}`,
      counterOfferValue: `$${convertToDecimals(emergencyFees)}`,
      counterOfferData: true,
      data: emergencyFees,
      amount: emergencyFees,
      counterOfferAmount: emergencyFees,
    },
    {
      key: '5',
      name: strings.travel,
      value: travelDisplay,
      data: !!travelAllowanceTypes?.length,
      amount: travelAllowance,

      // Counter offer
      counterOfferData: !!handleValues([
        counterTravelAllowance,
        counterPaidAirTicketGiven,
      ]).length,
      counterOfferValue: handleValues([
        counterTravelAllowance,
        counterPaidAirTicketGiven,
      ]),
      counterOfferAmount: counterTravelAllowance,
    },
    {
      key: '6',
      name: strings.meal,
      value: `${currency}${mealAllowance}`,
      data: !!mealAllowance,
      amount: mealAllowance,

      // Counter Offer
      counterOfferData: !!counterMealAllowance,
      counterOfferValue: `${currency}${counterMealAllowance}`,
      counterOfferAmount: counterMealAllowance,
    },
    {
      key: '7',
      name: strings.pharmaPlanFees,
      value: `$${convertToDecimals(pharmaPlanFees ?? 0)}`,
      counterOfferData: counterPharmaPlanFees,
      counterOfferValue: `$${convertToDecimals(counterPharmaPlanFees ?? 0)}`,
      data: pharmaPlanFees,
      amount: pharmaPlanFees,
      counterOfferAmount: counterPharmaPlanFees,
    },
  ];

  return { bookingDetailsConfig };
};
export default useBillDetails;
