import React, { Fragment, useEffect, useState } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useFormik } from 'formik';

import { ReactComponent as FavouriteNotActive } from '@pharmaplan/common/assets/icons/favouriteNotActive.svg';
import { ReactComponent as FavouriteActive } from '@pharmaplan/common/assets/icons/favourtieActive.svg';
import { Language, language } from '@pharmaplan/common';

import CustomRatingStar from '../../common/CustomRatingStar/CustomRatingStar';
import usePharmacistRating from '../../../hooks/Rating/usePharmacistRating';
import CustomButton from '../../common/CustomButton/CustomButton';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useSelfService from '../../../hooks/useSelfService';
import { resetDialog } from '../../../reducers/dialogReducer';
import CustomTextField from '../../common/CustomTextField';
import strings from '../../../localization';

import RatingQuestions from './RatingQuestions';
import Validator from './validator';
import styles from './styles';

interface IPharmacistRatingProps {
  pharmacistId: string;
  pharmacistName: string;
  bookingId: string;
}

const PharmacistRating = ({
  pharmacistId,
  pharmacistName,
  bookingId,
}: IPharmacistRatingProps) => {
  const [favState, setFavState] = useState(false);
  const [ratingLevel, setRatingLevel] = useState<{ [key: string]: number }>({});
  const { isSelfService } = useSelfService();

  const dispatch = useAppDispatch();

  const userLanguage = useAppSelector(language);

  const style = styles(favState);

  const isEN = userLanguage === Language.en;

  const {
    handleSubmit,
    bookedComment,
    maxRatedItemsPossible,
    data,
    userRatingItems,
    ratingData,
    idProperty,
    displayDetailForm,
    questions,
    ratingFeedback,
    questionProperty,
    title,
  } = usePharmacistRating({
    bookingId,
    id: pharmacistId,
    ratingLevel,
    favState,
  });

  const doesDataExist = data?.length;

  const formik = useFormik({
    initialValues: {
      numberOfRatedItems: 0,
      comment: doesDataExist ? bookedComment : '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: Validator(maxRatedItemsPossible),
  });

  const handleClose = () => {
    dispatch(resetDialog());
  };

  const onFavoriteClick = () => {
    setFavState((prev) =>
      !prev);
  };

  useEffect(() => {
    setFavState(ratingData.favorite);
  }, [ratingData.favorite]);

  useEffect(() => {
    formik.setFieldValue('numberOfRatedItems', Object.keys(ratingLevel).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingLevel]);

  useEffect(() => {
    formik.setValues({ ...formik.values, ...ratingFeedback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingFeedback]);

  useEffect(() => {
    setRatingLevel(
      data?.reduce((acc: Record<string, number>, cur) => {
        acc[cur?.[idProperty as keyof typeof cur] ?? ''] = cur?.level ?? 0;
        return acc;
      }, {}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box sx={style.ratingContainer}>
      <Box sx={style.closeContainer}>
        <IconButton size="small" sx={style.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={style.ratingTitle}>
        <Box>
          <Box>
            <Typography sx={style.title}>
              {title}
            </Typography>
          </Box>
          <Typography sx={style.title}>
            [
            {pharmacistName}
            ]
          </Typography>
        </Box>

        <Box>
          <CustomButton
            variant="outlined"
            startIcon={
              favState ? (
                <FavouriteActive style={style.buttonIcon} />
              ) : (
                <FavouriteNotActive style={style.buttonIcon} />
              )
            }
            label={strings.favourite}
            customButtonStyle={style.isfavoriteButton}
            onClick={onFavoriteClick}
          />
        </Box>
      </Box>

      {displayDetailForm
        && userRatingItems.map((item) => {
          const { englishName, frenchName } = item ?? {};
          const id = item[idProperty as keyof typeof item];

          return (
            <Fragment key={id}>
              <CustomRatingStar
                key={id}
                text={isEN ? englishName : frenchName}
                count={5}
                ratingLevel={ratingLevel[id] ?? 0}
                handleClick={(starIndex: number) =>
                  setRatingLevel((prev) =>
                    ({
                      ...prev,
                      [id]: starIndex,
                    }))}
              />
              <Divider />
            </Fragment>
          );
        })}

      {isSelfService && (
        <RatingQuestions
          idType={questionProperty}
          professionalRatingQuestions={questions}
          formik={formik}
        />
      )}

      <CustomTextField
        customClass={{ mt: '24px' }}
        variant="outlined"
        formik={formik}
        id="notes"
        label={strings.comment}
        placeholder={' '}
        name="comment"
        multiline
      />
      {formik.touched.numberOfRatedItems
        && formik.errors.numberOfRatedItems && (
          <Typography sx={style.error}>{strings.pleaseSelectRating}</Typography>
      )}

      <Box sx={style.buttonContainer}>
        <CustomButton
          disabled={!!doesDataExist}
          customClass={style.button}
          label={strings.save}
          onClick={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default PharmacistRating;
