import React, { FC } from 'react';

import {
  AllowanceTypesToHandle,
  BookingState,
  IAdminRequestedDetailsData,
  ICalendarEventType,
  IPharmacistDetails,
  IWorkshift,
  PharmacyLegendVariant,
  TypeOfSummary,
  convertToDecimals,
  userPreferredTimeFormat,
  workshiftData,
} from '@pharmaplan/common';
import { ReactComponent as CalendarIcon } from '@pharmaplan/common/assets/icons/calendarIconNew.svg';
import { ReactComponent as FeesIcon } from '@pharmaplan/common/assets/icons/feesIcon.svg';

import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';
import { getTimeWithDiff } from '../../Modals/WorkshiftDetails/utils';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useSelfService from '../../../hooks/useSelfService';
import ThemeConstants from '../../../theme/ThemeConstants';
import { repeatEvery } from '../../../helpers/Constants';

import ServiceCard from '../ServiceCard/ServiceCard';
import PharmacistSummaryContent from './PharmacistSummaryContent/PharmacistSummaryContent';
import DisplayRecurrence from './DisplayRecurrence/DisplayRecurrence';
import { displayIcon, handleValues, title } from './helpers';
import classes from './styles';

interface IPharmacistSummary {
  event: Partial<IWorkshift> &
    Partial<IPharmacistDetails> &
    Partial<IAdminRequestedDetailsData>;
  DateTime?: React.ReactNode | null;
  summaryType?: TypeOfSummary;
  isCounterOffer?: boolean;
  type: ICalendarEventType;
}

const { pharmacist, workshiftDetails } = TypeOfSummary;
const { booked } = PharmacyLegendVariant;
const { UpdatedTiming } = BookingState;

const {
  dateContainer,
  dateTimeContainer,
  recurrencePadding,
  workshiftContainer,
} = classes;

const { grey850 } = ThemeConstants.common;
const { paidAirTicket } = AllowanceTypesToHandle;

const PharmacistSummary: FC<IPharmacistSummary> = ({
  event,
  DateTime,
  summaryType,
  isCounterOffer,
  type,
}) => {
  const { isSelfService } = useSelfService();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const data = useAppSelector(workshiftData);
  const {
    travelAllowanceTypes: traveAllowanceDropdowns,
    accommodationAllowanceTypes,
  } = data ?? {};

  const {
    bookingState,
    counterEndDate = '',
    counterStartDate = '',
    startDate = '',
    endDate = '',
    recurrence,
    emergencyFees,
    accommodationAllowanceType,
    travelAllowanceTypes,
    accommodationAllowance,
    mealAllowance,
    paidHourlyRate = 0,
    travelAllowanceRatePerKm,
    travelAllowanceAmount,
    counterHourlyRate = 0,
    notes,
  } = event ?? {};

  const isBooked = type === booked;
  const isPharmacistSummary = summaryType === pharmacist;
  const isWorkshiftDetails = summaryType === workshiftDetails;
  const isUpdatedTiming = bookingState === UpdatedTiming && isBooked;

  const isTravelGiven = !!travelAllowanceTypes?.filter((item) =>
    item !== '')
    ?.length;
  const isAccommodationGiven = !!accommodationAllowanceType;
  const isMealGiven = !!mealAllowance;

  const paidAirTicketGiven = travelAllowanceTypes?.includes(paidAirTicket)
    && traveAllowanceDropdowns.find((item) =>
      item.key === paidAirTicket)?.value;

  const accommodationLabels = accommodationAllowanceTypes.find(
    (item) =>
      item.key === `${accommodationAllowanceType}`,
  )?.value;

  const updatedTimings = isUpdatedTiming
    ? {
      startDate: counterStartDate,
      endDate: counterEndDate,
    }
    : {
      startDate,
      endDate,
    };

  const { startDate: updatedStartDate, endDate: updatedEndDate } = updatedTimings ?? {};

  const hourlyRate = {
    paidHourlyRate,
    counterHourlyRate,
  };

  const recurrenceRepeat = repeatEvery()[
    (recurrence?.recurrenceType as keyof typeof repeatEvery) ?? 0
  ];

  const hours = getTimeWithDiff(updatedStartDate, updatedEndDate, timeFormat);
  const counterHours = getTimeWithDiff(
    counterStartDate,
    counterEndDate,
    timeFormat,
  );
  const dateLabel = `${getDayMonthDateYear(startDate)} | ${hours}`;
  const counterLabel = isUpdatedTiming ? hours : '';
  const counterDateLabel = `${getDayMonthDateYear(
    counterStartDate,
  )} | ${counterHours}`;

  const recurrenceStyle = DateTime ? recurrencePadding : workshiftContainer;

  const travelDisplay = displayIcon(
    isTravelGiven,
    handleValues([
      travelAllowanceRatePerKm,
      travelAllowanceAmount,
      paidAirTicketGiven,
    ]),
  );
  const accommodationDisplay = displayIcon(
    isAccommodationGiven,
    handleValues([accommodationAllowance, accommodationLabels]),
  );
  const mealDisplay = displayIcon(isMealGiven, handleValues([mealAllowance]));

  const { data: travelData, icon: travelIcon } = travelDisplay;
  const { data: accommodationData, icon: accommodationIcon } = accommodationDisplay;
  const { data: mealData, icon: mealIcon } = mealDisplay;

  const travelLabel = `${strings.travel}: ${travelData}`;

  const emergencyFeesLabel = `${
    strings.emergencyServiceFees
  }: $${convertToDecimals(emergencyFees)}`;

  const accomodationLabel = `${strings.accomodation}: ${accommodationData}`;

  const mealLabel = `${strings.meal}: ${mealData}`;

  const config = [
    {
      key: '0',
      component: <CalendarIcon fill={grey850} />,
      data: !!startDate,
      label: dateLabel,
      counterLabel,
      style: dateContainer,
    },
    DateTime
      ? {
        key: '1',
        component: DateTime,
        data: true,
        style: dateTimeContainer,
      }
      : {
        key: '11',
      },
    {
      key: '2',
      data: isPharmacistSummary,
      component: <DisplayRecurrence recurrenceRepeat={recurrenceRepeat} />,
      style: recurrenceStyle,
    },
    {
      key: '3',
      label: emergencyFeesLabel,
      data: isSelfService && emergencyFees,
      component: <FeesIcon fill={grey850} />,
      style: workshiftContainer,
    },
    {
      key: '4',
      label: travelLabel,
      component: travelIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
    {
      key: '5',
      label: accomodationLabel,
      component: accommodationIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
    {
      key: '6',
      label: mealLabel,
      component: mealIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
  ];

  const counterOfferConfig = isCounterOffer && isWorkshiftDetails
    ? [
      {
        key: '0',
        component: <CalendarIcon fill={grey850} />,
        data: startDate,
        label: dateLabel,
        counterData: counterStartDate,
        counterLabel: counterDateLabel,
        style: dateContainer,
      },
    ]
    : [];

  const cardTitle = title(summaryType);
  const summaryContent = isCounterOffer ? counterOfferConfig : config;
  const displayNote = isPharmacistSummary ? notes : null;

  return (
    <ServiceCard
      title={cardTitle}
      isCounterOffer={isCounterOffer}
      hourlyRate={hourlyRate}
      content={(
        <PharmacistSummaryContent
          summaryContent={summaryContent}
          notes={displayNote}
        />
      )}
    />
  );
};

export default PharmacistSummary;
