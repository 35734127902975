import React, { FC } from 'react';

import {
  TypeOfBill,
  TypeOfUser,
  areValuesDifferent,
  convertToDecimals,
  getTypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import useBillDetails from '../../../hooks/BillDetails/useBillDetails';
import { getTimeWithDiff } from '../../Modals/WorkshiftDetails/utils';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

import ServiceCard from '../ServiceCard/ServiceCard';
import BillContent from './BillContent/BillContent';
import BillFooter from './BillFooter/BillFooter';
import { IBillDetails } from './types';

const { pharmacist, pharmacy } = TypeOfUser;

const BillDetails: FC<IBillDetails> = ({
  billType,
  event,
  percentFees,
  isCounterOffer,
  successAction,
}) => {
  const { counterStartDate = '', counterEndDate = '' } = event ?? {};

  const userType = useAppSelector(getTypeOfUser);

  const isPharmacist = userType === pharmacist;
  const isPharmacy = userType === pharmacy;

  const suffix = isPharmacy ? ` + ${strings.tax}` : '';

  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const counterOfferTime = getTimeWithDiff(
    counterStartDate,
    counterEndDate,
    timeFormat,
  );
  const { bookingDetailsConfig } = useBillDetails(event, percentFees);

  const rateAndTime = isPharmacist
    ? [
      {
        key: '0',
        name: strings.time,
        value: '',
        data: true,
        counterOfferData: counterStartDate,
        counterOfferValue: `${counterOfferTime}`,
      },
    ]
    : [];

  const counterOfferCriteria = isCounterOffer
    ? {
      checkCriteria: 'counterOfferData',
      checker: areValuesDifferent,
    }
    : {
      checkCriteria: 'data',
      checker: () =>
        true,
    };

  const billTotal = convertToDecimals(
    bookingDetailsConfig?.reduce((acc, curr) =>
      acc + (curr.amount ?? 0), 0),
  );

  const counterOfferBillTotal = convertToDecimals(
    bookingDetailsConfig?.reduce(
      (acc, curr) =>
        acc + (curr.counterOfferAmount ?? 0),
      0,
    ),
  );

  const cardTitle = () => {
    switch (billType) {
      case TypeOfBill.bill:
        return strings.billDetails;
      case TypeOfBill.finalBill:
        return strings.contractDetails;
      case TypeOfBill.counterOffer:
        return isPharmacist ? strings.counterOffer : strings.billDetails;
      default:
        return strings.billDetails;
    }
  };

  return (
    <ServiceCard
      title={cardTitle()}
      isCounterOffer={isCounterOffer}
      content={(
        <BillContent
          successAction={successAction}
          counterOfferCriteria={counterOfferCriteria}
          rateAndTime={rateAndTime}
          billDetails={bookingDetailsConfig}
          isCounterOffer={isCounterOffer}
        />
      )}
      footer={(
        <BillFooter
          successAction={successAction}
          suffix={suffix}
          total={billTotal}
          counterTotal={isCounterOffer && counterOfferBillTotal}
        />
      )}
    />
  );
};

export default BillDetails;
