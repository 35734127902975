import { Editor } from '@tiptap/core';
import { ReactComponent as HeaderOneIcon } from '../../assets/svg/h-1.svg';
import { ReactComponent as HeaderTwoIcon } from '../../assets/svg/h-2.svg';
import { ReactComponent as HeaderThreeIcon } from '../../assets/svg/h-3.svg';
import { ReactComponent as FormatBoldIcon } from '../../assets/svg/bold.svg';
import { ReactComponent as FormatItalicIcon } from '../../assets/svg/italic.svg';

interface IUseEditorToolbar {
  editor: Editor | null;
}

const useEditorToolbar = ({ editor }: IUseEditorToolbar) => {
  const focusInstance = () =>
    editor?.chain().focus();

  const toggleBold = () => {
    focusInstance?.()?.toggleBold().run();
  };

  const toggleItalic = () => {
    focusInstance?.()?.toggleItalic().run();
  };

  const toggleHeadingLevel = (level: any) =>
    () => {
      focusInstance?.()?.toggleHeading({ level }).run();
    };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]; // Get the selected file

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = (reader.result as string).split(',');
        const imageSrc = base64String;

        if (editor) {
          const { view } = editor ?? {};
          const { state } = view ?? {};
          const { schema } = state ?? {};
          const pos = editor?.state.doc.content.size ?? 0;

          const node = schema?.nodes.image.create({ src: imageSrc });
          const transaction = view?.state.tr.insert(pos ?? 0, node ?? []);

          return view?.dispatch(transaction);
        }

        return null;
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    } else {
      alert('No file selected');
    }
  };

  const toolbarConfig = [
    {
      key: 1,
      icon: HeaderOneIcon,
      handleClick: toggleHeadingLevel(1),
      isActive: editor?.isActive('heading', { level: 1 }),
    },
    {
      key: 2,
      icon: HeaderTwoIcon,
      handleClick: toggleHeadingLevel(2),
      isActive: editor?.isActive('heading', { level: 2 }),
    },
    {
      key: 3,
      icon: HeaderThreeIcon,
      handleClick: toggleHeadingLevel(3),
      isActive: editor?.isActive('heading', { level: 3 }),
    },
    {
      key: 4,
      icon: FormatBoldIcon,
      handleClick: toggleBold,
      isActive: editor?.isActive('bold'),
    },
    {
      key: 5,
      icon: FormatItalicIcon,
      handleClick: toggleItalic,
      isActive: editor?.isActive('italic'),
    },
  ];

  return { toolbarConfig, handleFileUpload };
};

export default useEditorToolbar;
