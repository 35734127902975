/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material';

import classes from './style';
import Checkbox from '../Checkbox';

interface ICustomCheckbox {
  label: string | React.JSX.Element;
  name: string;
  customCheckboxStyle?: SxProps<Theme>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value: boolean;
  id: string;
  customClass?: Record<string, unknown> | Array<Record<string, unknown>>;
  labelClass?: TypographyProps<'span', object>;
  linkLabel?: string | React.JSX.Element;
  onLinkClick?: () => void;
  makeWholeClickable?: boolean;
}

const CustomCheckbox = ({
  id,
  handleChange,
  value,
  disabled = false,
  label,
  customCheckboxStyle,
  customClass = {},
  labelClass = {},
  name,
  linkLabel = '',
  onLinkClick = () =>
    null,
  makeWholeClickable = false,
}: ICustomCheckbox) => {
  if (linkLabel) {
    let onClick = onLinkClick;

    if (!makeWholeClickable || value) {
      onClick = () => {};
    }

    return (
      <Grid onClick={onClick} container sx={classes.container}>
        <FormControl sx={classes.formControl}>
          <Checkbox
            id={id}
            onChange={handleChange}
            disabled={disabled}
            name={name}
            checked={value || false}
          />
        </FormControl>

        <FormLabel sx={classes.formLabel} focused={false}>
          <Box component="span">{label}</Box>
          <Box
            component="span"
            style={classes.linkLabel}
            onClick={onLinkClick}
            onKeyDown={onLinkClick}
          >
            {linkLabel}
          </Box>
        </FormLabel>
      </Grid>
    );
  }

  return (
    <FormControlLabel
      control={(
        <Checkbox
          id={id}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          sx={customCheckboxStyle}
        />
      )}
      label={label}
      checked={value}
      slotProps={{ typography: labelClass }}
      sx={customClass}
    />
  );
};

export default CustomCheckbox;
