import { IStore } from '../types/IStore';

export const adminCalendar = (state: IStore) => state.admin.calendar;
export const adminMap = (state: IStore) => state.admin.map;
export const activeUserList = (state: IStore) => state.admin.activeUsers;
export const adminEvent = (state: IStore) => state.admin.adminEvent;
export const adminWorkshiftDetails = (state: IStore) => state.admin.workshiftDetails;
export const requestedPharmacists = (state: IStore) => state.admin.requestedPharmacists;
export const adminRequestedDetails = (state: IStore) => state.admin.requestedDetails;
export const adminIgnoreData = (state: IStore) => state.admin.adminIgnoreData;
export const adminApproveData = (state: IStore) => state.admin.adminApproveData;
export const selectMatchingAvailabilities = (state: IStore) => state.admin.matchingAvailabilities;
export const bookAvailabilityData = (state: IStore) => state.admin.bookAvailability;
export const adminBookingDetails = (state: IStore) => state.admin.bookedPharmacistData
export const adminRatingItems = (state: IStore) => state.admin.ratingItems;
export const adminRatingDetails = (state: IStore) => state.admin.ratingDetails;
export const selectRequestCancellation = (state: IStore) => state.admin.requestCancellation;
export const adminCancelMessage = (state: IStore) => state.admin.cancelBookingMessage;
export const selectOtherAvailabilities = (state: IStore) => state.admin.otherAvailabilities;
export const pharmacyDetails = (state: IStore) => state.admin.pharmacyDetails;
export const pharmacistDetails = (state: IStore) => state.admin.pharmacistDetails;
export const searchedPharmacists = (state: IStore) => state.admin.searchedPharmacists;
export const searchedPharmacies = (state: IStore) => state.admin.searchedPharmacies;
export const searchedAllUsers = (state: IStore) => state.admin.allSearchedUsers;
export const isIncompatbileMatchSelector = (state: IStore) => state.admin.isIncompatibleMatch
export const pendingActivationList = (state:IStore) => state.admin.pendingActivations;
export const adminSoftwareList = (state: IStore) => state.admin.softwareList;
export const adminSoftware = (state: IStore) => state.admin.software;
export const adminPharmacistCapacityList = (state: IStore) => state.admin.pharmacistCapacityList;
export const adminPharmacistCapacity = (state: IStore) => state.admin.pharmacistCapacity;
export const adminContactPreferenceList = (state: IStore) => state.admin.contactPreferenceList;
export const adminConfiguration = (state: IStore) => state.admin.adminConfiguration;
export const salesforceAdmins = (state: IStore) => state.admin.salesforceAdmins;
export const adminPharmacyChainList = (state: IStore) => state.admin.pharmacyChainList;
export const adminPharmacyChain = (state: IStore) => state.admin.pharmacyChain;
export const pharmacistRegionList = (state: IStore) => state.admin.pharmacistRegionList;
export const pendingCancellationsList = (state: IStore) => state.admin.pendingCancellations;
export const adminPharmacistResume = (state: IStore) => state.admin.adminPharmacistResume;
export const pendingCancellationDetails = (state: IStore) => state.admin.pendingCancellationDetails;
export const activePharmacistLists = (state: IStore) => state.admin.activePharmacistLists;
export const cancelledBookingsList = (state: IStore) => state.admin.cancelledBookings;
export const cancelledBookingsDetails = (state: IStore) => state.admin.cancelledBookingDetails;
export const rejectedCancellationsList = (state: IStore) => state.admin.rejectedCancellations;
export const adminAllNotes = (state: IStore) => state.admin.adminAllNotes;
export const adminPharmacyAllNotes = (state: IStore) => state.admin.adminPharmacyAllNotes;
export const adminNote = (state: IStore) => state.admin.adminNote;
export const adminPharmacyNote = (state: IStore) => state.admin.adminPharmacyNote;
export const pharmacistBookingList = (state: IStore) => state.admin.pharmacistBookingList;
export const pharmacistBooking = (state: IStore) => state.admin.pharmacistBooking;
export const pharmacistAvailabilities = (state: IStore) => state.admin.pharmacistAvailabilities;
export const pharmacistMatchingWorkshifts = (state: IStore) => state.admin.matchingWorkshifts;
export const selectPharmacistPitures = (state: IStore) => state.admin.pharmacistPictures;
export const adminPendingActivationsCount = (state: IStore) => state.admin.pendingActivationsCount;
export const adminOwnersList = (state: IStore) => state.admin.ownersList;
export const selectActiveUsersSegment = (state: IStore) => state.admin.activeUsersSegment;
export const adminListOfPharmacies = (state: IStore) => state.admin.listOfPharmacies;
export const adminPharmacistNotes = (state: IStore) => state.admin.pharmacistNotes;
export const adminPharmacistHistory = (state: IStore) => state.admin.pharmacistHistory;
export const adminDeactivatedPharmacists = (state: IStore) => state.admin.deactivatedPharmacists;
export const adminHiddenPharmacists = (state: IStore) => state.admin.hiddenPharmacists;
export const adminDeletedPharmacists = (state: IStore) => state.admin.deletedPharmacists;
export const adminActivePharmacyList = (state: IStore) => state.admin.activePharmacyList;
export const publishedSoftwareList = (state: IStore) => state.admin.publishedSoftwareList;
export const adminListOfPharmacists = (state: IStore) => state.admin.listOfPharmacists;
export const adminPharmacyNotes = (state: IStore) => state.admin.pharmacyNotes;
export const adminPharmacyHistory = (state: IStore) => state.admin.pharmacyHistory;
export const adminIncompatibleMatches = (state: IStore) => state.admin.incompatibleMatches;
export const pharmacyWorkshiftsList = (state: IStore) => state.admin.pharmacyWorkshiftsList;
export const adminDeactivatedPharmacyList = (state: IStore) => state.admin.deactivatedPharmacyList;
export const adminDeletedPharmacyList = (state: IStore) => state.admin.deletedPharmacyList;
export const adminIncompatibleMatchesSearch = (state: IStore) => state.admin.incompatibleMatchesSearch;
export const adminReports = (state: IStore) => state.admin.adminReports;
export const adminReportsFilter = (state: IStore) => state.admin.adminReportsFilter;
export const adminReportsPDF = (state: IStore) => state.admin.adminReportsPDF;
export const adminList = (state: IStore) => state.admin.adminsList;
export const adminAllAvailabilities = (state: IStore) => state.admin.adminAllAvailabilities;
export const allAdminPermissions = (state: IStore) => state.admin.allPermissions;
export const adminDetails =  (state:IStore) => state.admin.adminDetails;
export const adminContactPreference = (state: IStore) => state.admin.contactPreference;
export const adminPharmacistSearchedDetails = (state: IStore) => state.admin.pharmacistDetailsSearch;
export const adminPharmacistSearchedDetailsFilters = (state: IStore) => state.admin.pharmacistSearchDetailsFilter;
export const adminPharmaciesInfo = (state: IStore) => state.admin.pharmaciesInfo;
export const adminPharmacistRatings = (state: IStore) => state.admin.pharmacistRatings
export const adminSettings = (state:IStore) => state.admin.adminSettings;
export const adminBroadcastGroupUsers = (state: IStore) => state.admin.broadcastGroupUsers;
export const adminBroadcastGroupList = (state: IStore) => state.admin.broadcastGroupList;
export const adminBroadcastList = (state: IStore) => state.admin.broadcastGroupList;
export const adminBroadcastFilter = (state: IStore) => state.admin.broadcastGroupFilters;
export const adminBroadcastGroup = (state: IStore) => state.admin.broadcastGroup;
export const adminBroadcastNotifications = (state: IStore) => state.admin.broadcastNotifications
export const adminBroadcastRecipientList = (state: IStore) => state.admin.groupRecipientList
export const adminBroadcastNotification = (state: IStore) => state.admin.broadcastNotification;
export const adminWorkshiftLogs = (state: IStore) => state.admin.workshiftLogs;
export const adminAvailabilitiesLogs = (state: IStore) => state.admin.availabilitiesLogs;
export const adminBookingLogs = (state: IStore) => state.admin.bookingLogs;
export const adminActivityLogs = (state: IStore) => state.admin.activityLogs;
export const adminLogsFilters = (state: IStore) => state.admin.logsFilters;
export const allBroadcastGroups = (state: IStore) => state.admin.allBroadcastGroups;
export const activeUserCount = (state: IStore) => state.admin.activeUserCount;
export const adminInfo = (state: IStore) => state.admin.adminInfo;
export const pharmacyAdminList = (state: IStore) => state.admin.pharmacyAdminList;
export const pharmacyAdminDetails = (state: IStore) => state.admin.pharmacyAdminDetails;
export const adminPharmacyAdminProfile = (state: IStore) => state.admin.pharmacyAdminProfile;
export const pharmaciesForPharmacyAdmin = (state: IStore) => state.admin.pharmaciesForPharmacyAdmin;
export const adminName = (state: IStore) => state.admin.adminInfo.userName;
export const appointedPharmacyList = (state: IStore) => state.admin.appointedPharmacyList;
export const adminWatchlistedWorkshifts = (state: IStore) => state.admin.watchlistedWorkshifts;
export const adminBroadcastGroupSelectedUsers = (state: IStore) => state.admin.broadcastGroupSelectedUsers;
export const adminCalendarBanners = (state: IStore) => state.admin.calendarBanners;
export const adminPharmacistVacationDetails = (state: IStore) => state.admin.adminPharmacistVacations;
export const adminPharmacistPreSubmitVacations = (state: IStore) => state.admin.adminPharmacistPreSubmitVacations;
export const adminPharmacistOnVacation = (state: IStore) => state.admin.adminPreSubmitAvailabilities;
export const adminIsAvaiabailityRequested = (state: IStore) => state.admin.preSubmitDeleteAvailabilities;
export const adminActiveUserStatistics = (state: IStore) => state.admin.activeUserStatistics;
export const adminCancellationStatistics = (state: IStore) => state.admin.cancellationStatistics;
export const adminFinancialStatistics = (state: IStore) => state.admin.financialStatistics;
export const adminWorkshiftStatistics = (state: IStore) => state.admin.workshiftStatistics;
export const adminDailyStats = (state: IStore) => state.admin.dailyStatistics;
export const adminPharmacyAdminPicture = (state: IStore) => state.admin.pharmacyAdminPicture;
export const adminAllClients = (state: IStore) => state.admin.allClientUsers;
export const allAdvertisementsSelector = (state: IStore) => state.admin.allAdvertisements;
export const advertisementSelector = (state: IStore) => state.admin.advertisement;
export const advertisementFilters = (state: IStore) => state.admin.advertisementFilters;
export const adminProfessionalRatingItems = (state: IStore) => state.admin.professionalRatingItems;
export const adminPharmacyRatingItems = (state: IStore) => state.admin.pharmacyRatingItems;
export const adminProfessionalRatingDetails = (state: IStore) => state.admin.professionalRatingDetails;
export const adminPharmacyRatingDetails = (state: IStore) => state.admin.pharmacyRatingDetails;
export const adminProfessionalRatings = (state: IStore) => state.admin.professionalRatings
export const adminPharmacyRatings = (state: IStore) => state.admin.pharmacyRatings
export const adminProfessionalDetails = (state: IStore) => state.admin.professionalDetails