import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import LogoutHandler from '@pharmaplan/common/helpers/LogoutHandler';
import { getTypeOfUser, TypeOfUser } from '@pharmaplan/common';

import { Constants } from '../helpers/Constants';

import { useAppSelector } from './useAppSelector';

const {
  pharmacist,
  pharmacy,
  admin,
  pharmacyAdmin,
  superAdmin,
  technicians,
  pharmacistStudents,
} = TypeOfUser;
const {
  paths: {
    calendarDashboard: calendarRoute,
    admin: { adminCalendarDashboard },
    pharmacyAdmin: { home },
  },
} = Constants;

const useNavAfterLogin = () => {
  const userType = useAppSelector(getTypeOfUser);
  const navigate = useNavigate();

  const isUserLoggedIn = !!LogoutHandler.isUserLoggedIn();

  const nextRouteConfig = {
    [pharmacist]: calendarRoute,
    [technicians]: calendarRoute,
    [pharmacistStudents]: calendarRoute,
    [pharmacy]: calendarRoute,
    [admin]: adminCalendarDashboard,
    [pharmacyAdmin]: home,
    [superAdmin]: adminCalendarDashboard,
  };

  const nextRoute = nextRouteConfig[userType];

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate(nextRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);
};

export default useNavAfterLogin;
