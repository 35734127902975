import React from 'react';

import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { ReactComponent as WelcomeToPharmaGapIcon } from '../../assets/svg/pharmagapWelcome.svg';
import strings from '../../localization';
import CustomButton from '../common/CustomButton';
import { Constants } from '../../helpers/Constants';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { resetDialog } from '../../reducers/dialogReducer';

import styles from './styles';

const WelcomeToPharmaGap = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToBilling = () => {
    navigate(Constants.paths.profile);
  };

  const close = () => {
    dispatch(resetDialog());
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.icon}>
        <WelcomeToPharmaGapIcon />
      </Box>
      <Typography sx={[styles.title, styles.textAlign]}>
        {strings.thankYouForJoiningPharmaGap}
      </Typography>
      <Typography sx={[styles.description, styles.textAlign]}>
        {strings.welcomeToPharmaGapSubMessage}
      </Typography>

      <Box sx={styles.buttonContainer}>
        <CustomButton
          label={strings.proceed}
          customButtonStyle={styles.proceed}
          onClick={navigateToBilling}
        />
        <CustomButton
          label={strings.later}
          onClick={close}
          variant="outlined"
          customButtonStyle={styles.proceed}
        />
      </Box>
    </Box>
  );
};

export default WelcomeToPharmaGap;
