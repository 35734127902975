import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import PharmacyController from '../controllers/PharmacyController';
import {
  ICreateWorkshiftParams,
  IHistoryPharmacyApiParams,
  IPharmacyRatesProfessional,
  IPharmacyReportParams,
  IRateLevels,
  IReportIssue,
  IUpdateTimingsParams,
  IUpdateWorkshiftParams,
} from '../models/PharmacyModels';
import { statusDispatch } from './globalActions';
import { IBookedPharmacistDetails } from '../controllers/types';
import { TypeOfUser } from '../helpers/Constants';
import { ICancelBooking } from '../models';
import { IReplacementsParams } from '../models/Models';

export const pharmacyActions = {
  replacements: 'pharmacy/replacements',
  history: 'pharmacy/history',
  historyPagination: 'pharmacy/history/pagination',
  calendar: 'pharmacy/calendar',
  calendarNoLoad: 'pharmacy/calendar/no/loader',
  notes: 'pharmacy/notes',
  reports: 'pharmacy/reports',
  reportsPage: '/pharmacy/reports/page',
  createNote: 'pharmacy/note/create',
  updateNote: 'pharmacy/note/update',
  deleteNote: 'pharmacy/note/delete',
  workshift: 'pharmacy/workshift',
  reportFilter: 'pharmacy/report/filter',
  reportsPdf: 'pharmacy/report/pdf',
  replacementsPdf: 'pharmacy/replacements/pdf',
  createWorkshift: 'pharmacy/workshift/create',
  updateWorkshift: 'pharmacy/workshift/update',
  deleteWorkshift: 'pharmacy/workshift/delete',
  addFavourite: 'pharmacy/favourite/add',
  removeFavourite: 'pharmacy/favourite/remove',
  cancelRequestedBooking: 'pharmacy/booking/cancel',
  getBookedPharmacistDetails: 'pharmacy/booked/details',
  requestedBookings: 'pharmacy/booking/requested',
  getHourlyRate: 'pharmacy/get/hourlyRate',
  approveRequest: 'pharmacy/approve/request',
  ignoreRequest: 'pharmacy/ignore/request',
  rate: 'pharmacy/rate',
  rating: 'pharmacy/rating',
  ratingItems: 'pharmacy/ratingitems',
  updateTimings: 'pharmacy/updatetTimings',
  cancelBookings: 'pharmacy/cancel/bookings',
  reportIssue: 'pharmacy/reportissue',
  reportIssueData: 'pharmacy/reportIssueData',
  bookedRating: 'pharmacy/bookedRating',
  replacementsWeb: 'pharmacy/replacements/web',
  doesPharmacyExist: 'get/pharmacy/exists',
  resetPharmacyExists: 'reset/pharmacy/exists',
  addIncompatible: 'pharmacy/add/incompatible',
  removeIncompatible: 'pharmacy/remove/incompatible',
  pharmacyPreApprove: 'pharmacy/preapprove',
  pharmacistReplacementDetails: 'pharmacy/get/replacement/details',
};

export const getPharmacyCalendar = createAsyncThunk(
  pharmacyActions.calendar,
  async (
    params: { startDate: string; endDate: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.pharmacyCalendar(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyCalendarWithoutLoad = createAsyncThunk(
  pharmacyActions.calendarNoLoad,
  async (
    params: { startDate: string; endDate: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.pharmacyCalendar(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReportsPdf = createAsyncThunk(
  pharmacyActions.reportsPdf,
  async (params: Omit<IPharmacyReportParams, 'page'>, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.reportsPdf(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReplacementPdf = createAsyncThunk(
  pharmacyActions.replacementsPdf,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.replacementsPdf();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createWorkshift = createAsyncThunk(
  pharmacyActions.createWorkshift,
  async (params: ICreateWorkshiftParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.createWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReports = createAsyncThunk(
  pharmacyActions.reports,
  async (params: IPharmacyReportParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.reports(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReportFilter = createAsyncThunk(
  pharmacyActions.reportFilter,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.pharmacyReportFilter();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyAddFavourite = createAsyncThunk(
  pharmacyActions.addFavourite,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.addFavourite(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyRemoveFavourite = createAsyncThunk(
  pharmacyActions.removeFavourite,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.removeFavourite(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReportsPage = createAsyncThunk(
  pharmacyActions.reportsPage,
  async (params: IPharmacyReportParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.reports(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteWorkshift = createAsyncThunk(
  pharmacyActions.deleteWorkshift,
  async (
    params: { id?: string; recurrenceId?: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.deleteWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue;
    }
  }
);

export const updateWorkshift = createAsyncThunk(
  pharmacyActions.updateWorkshift,
  async (params: IUpdateWorkshiftParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.updateWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getPharmacyWorkshift = createAsyncThunk(
  pharmacyActions.workshift,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.workshift(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReplacements = createAsyncThunk(
  pharmacyActions.replacements,
  async (params: IReplacementsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.replacements(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyReplacementsWeb = createAsyncThunk(
  pharmacyActions.replacementsWeb,
  async (params: IReplacementsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.replacements(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyHistory = createAsyncThunk(
  pharmacyActions.history,
  async (params: IHistoryPharmacyApiParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.history(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyHistoryPagination = createAsyncThunk(
  pharmacyActions.historyPagination,
  async (params: IHistoryPharmacyApiParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.history(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyNotes = createAsyncThunk(
  pharmacyActions.notes,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.notes(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePharmacyNote = createAsyncThunk(
  pharmacyActions.updateNote,
  async (
    params: { pharmacistNotesId: string; note: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.updateNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createPharmacyNote = createAsyncThunk(
  pharmacyActions.createNote,
  async (
    params: { pharmacistId: string; note: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.createNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePharmacyNote = createAsyncThunk(
  pharmacyActions.deleteNote,
  async (noteId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.deleteNote(noteId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyCancelBooking = createAsyncThunk(
  pharmacyActions.cancelRequestedBooking,
  async (params: ICancelBooking, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.requestCancellationBooking(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBookedPharmacistDetails = createAsyncThunk(
  pharmacyActions.getBookedPharmacistDetails,
  async ({ id, eventType }: IBookedPharmacistDetails, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.bookedPharmacistDetails({
        id,
        eventType,
      });
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBookingRequested = createAsyncThunk(
  pharmacyActions.requestedBookings,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.bookingRequestedList(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHourlyRate = createAsyncThunk(
  pharmacyActions.getHourlyRate,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.getHourlyRate();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRatingItems = createAsyncThunk(
  pharmacyActions.ratingItems,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.ratingItems(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const approveRequest = createAsyncThunk(
  pharmacyActions.approveRequest,
  async (
    { bookingId, userType }: { bookingId: string; userType: TypeOfUser },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.approveRequest(userType, bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRating = createAsyncThunk(
  pharmacyActions.rating,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.rating(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBookedRating = createAsyncThunk(
  pharmacyActions.bookedRating,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.getBookedRating(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const rate = createAsyncThunk(
  pharmacyActions.rate,
  async (params: IPharmacyRatesProfessional, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.rate(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ignoreRequest = createAsyncThunk(
  pharmacyActions.ignoreRequest,
  async (
    { bookingId, userType }: { bookingId: string; userType: TypeOfUser },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacyController.ignoreRequest(userType, bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateTimings = createAsyncThunk(
  pharmacyActions.updateTimings,
  async (params: IUpdateTimingsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.updateTimings(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const reportIssue = createAsyncThunk(
  pharmacyActions.reportIssue,
  async (params: IReportIssue, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.reportIssue(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportIssue = createAsyncThunk(
  pharmacyActions.reportIssueData,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.getReportIssue();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const selfPharmacyCancelBooking = createAsyncThunk(
  pharmacyActions.cancelBookings,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.selfPharmacyCancelBooking(
        bookingId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const doesPharmacyExist = createAsyncThunk(
  pharmacyActions.doesPharmacyExist,
  async (name: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.doesPharmacyExist(name);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyAddIncompatible = createAsyncThunk(
  pharmacyActions.addIncompatible,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.addIncompatible(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyRemoveIncompatible = createAsyncThunk(
  pharmacyActions.removeIncompatible,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.removeIncompatible(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacyPreApprove = createAsyncThunk(
  pharmacyActions.pharmacyPreApprove,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.pharmacyPreApprove(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistReplacementDetails = createAsyncThunk(
  pharmacyActions.pharmacistReplacementDetails,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyController.pharmacistReplacementDetails(
        pharmacistId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPharmacyExists = createAction(
  pharmacyActions.resetPharmacyExists
);
