import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import {
  AllowanceTypes,
  AllowancesNames,
  convertToDecimals,
  counterOfferCriteria,
  keyToParse,
  pharmacistActions,
  pharmacistEvent,
  requestBooking,
  resetStatus,
  successSelector,
  userPreferredTimeFormat,
  currency,
  ICalendarEventType,
} from '@pharmaplan/common';
import AllowanceWorkshift from '../Workshifts/AllowanceWorkshift';
import NotesField from '../../common/NotesField/NotesField';
import DateTimeRange from '../../common/DateTimeRange/DateTimeRange';
import { appendTimeToDate } from '../../../helpers/workshift/functions';
import { selectFooterPaginationCurrent } from '../../../selectors/drawerSelector';
import {
  renderScreen,
  resetDrawer,
  setBack,
  setButtons,
  setPagination,
} from '../../../actions/drawerActions';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useSelfService from '../../../hooks/useSelfService';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

import { MomentTimeFormats, ScreenTypes } from '../../../helpers/Constants';
import { timeString } from '../../../helpers/Functions';
import strings from '../../../localization';

import Validator from './Validator';
import classes from './styles';
import {
  decimalConverterProxy,
  handlerForZeroes,
} from '../../../helpers/proxyHandlers';
import { toJSDate } from '../../Dashboard/MainCalendar/helpers';
import { getDisableAvailability } from '../../../selectors/calendarSelector';

interface ISubmitCounterOffer {
  id: string;
  type: ICalendarEventType;
  eventIds: Array<string>;
}

const {
  travelAllowanceType,
  accommodationAllowanceType,
  fixedTravelAmount,
  paidHourlyRate,
  fixedAccommodationRate,
  fixedMealRate,
} = AllowancesNames;

const {
  align,
  timeContainer,
  title: titleStyle,
  counterValueText,
  container,
  divider,
  counterText,
  itemsContainer,
  labelContainer,
} = classes;

const { fullDate } = MomentTimeFormats;
const { custom, notCovered, covered } = AllowanceTypes;

const SubmitCounterOffer: FC<ISubmitCounterOffer> = ({
  id,
  type,
  eventIds,
}) => {
  const dispatch = useAppDispatch();
  const { workshiftParser, allowedValuesParser } = useSelfService();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const current = useAppSelector(selectFooterPaginationCurrent);
  const event = useAppSelector(pharmacistEvent);
  const disabled = useAppSelector(getDisableAvailability);

  const success = useAppSelector((state) =>
    successSelector([pharmacistActions.requestBooking], state));

  const {
    startDate,
    endDate,
    hourlyRate,
    mealAllowance,
    travelAllowance,
    accommodationAllowance = 0,
    accommodationAllowanceType: eventAccommodationAllowanceType,
    travelAllowanceType: eventTravelAllowanceType,
  } = event ?? {};

  const handleSubmit = (values: FormikValues) => {
    const parsedValues = workshiftParser(values, keyToParse);

    const allowedValues = allowedValuesParser(
      values,
      parsedValues,
      counterOfferCriteria(),
    );

    const { startTime, endTime, notes } = values ?? {};
    const {
      paidHourlyRate: parsedHourlyRate,
      fixedAccommodationRate: fixedAccommodationAmount,
    } = parsedValues ?? {};

    const allowanceTypes = {
      [travelAllowanceType]: values[travelAllowanceType],
      [accommodationAllowanceType]: values[accommodationAllowanceType],
    };

    dispatch(
      requestBooking({
        workshiftid: id,
        counterOffer: {
          ...allowanceTypes,
          ...parsedValues,
          ...allowedValues,
          startDate: appendTimeToDate(startDate, startTime).toFormat(fullDate),
          endDate: appendTimeToDate(endDate, endTime).toFormat(fullDate),
          notes,
          hourlyRate: parsedHourlyRate,
          fixedAccommodationAmount,
          fixedTravelAmount: parseFloat(values[fixedTravelAmount] || '0'),
        },
      }),
    );
  };

  const allowances = {
    [paidHourlyRate]: hourlyRate,
    [fixedTravelAmount]: travelAllowance,
    [fixedAccommodationRate]: accommodationAllowance,
    [fixedMealRate]: mealAllowance,
  };

  const handledForZeroAllowances = new Proxy(allowances, handlerForZeroes);
  const handledForDecimals = new Proxy(
    handledForZeroAllowances,
    decimalConverterProxy,
  );

  const formik = useFormik({
    initialValues: {
      startTime: toJSDate(startDate),
      endTime: toJSDate(endDate),
      notes: '',

      [travelAllowanceType]: eventTravelAllowanceType?.toString(),
      [accommodationAllowanceType]: eventAccommodationAllowanceType?.toString(),
      ...handledForDecimals,
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  const valueConfig = (value: number) =>
    ({
      [covered]: strings.covered,
      [notCovered]: strings.notCovered,
      [custom]: `$${convertToDecimals(value)}`,
    });

  const travelValue = valueConfig(travelAllowance)[
      eventTravelAllowanceType as keyof typeof valueConfig
  ];
  const accommodationValue = valueConfig(accommodationAllowance)[
    eventAccommodationAllowanceType as keyof typeof valueConfig
  ];
  const mealValue = 0;

  const config = [
    {
      key: 0,
      title: strings.time.toUpperCase(),
      value: `${timeString(startDate, endDate, timeFormat)}`,
    },
    {
      key: 1,
      title: strings.hourlyRate.toUpperCase(),
      value: `${currency}${convertToDecimals(hourlyRate)}`,
    },
    {
      key: 2,
      title: strings.travel.toUpperCase(),
      value: travelValue,
    },
    {
      key: 3,
      title: strings.accomodation.toUpperCase(),
      value: accommodationValue,
    },
    {
      key: 4,
      title: strings.meal.toUpperCase(),
      value: mealValue,
    },
  ];

  useEffect(() => {
    dispatch(
      setBack(() => {
        dispatch(
          renderScreen({
            eventId: id,
            eventIds,
            type,
            screenNumber: 1,
            screenType: ScreenTypes.serviceWorkshiftDetails,
          }),
        );
      }),
    );

    // We don't need the back button for Workshift Details screen
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(resetDrawer());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(setPagination({ show: false, current }));

    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.submit,
          disabled: !disabled,
          onClick: formik.handleSubmit,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const handleNegativeDates = () => {
    formik.setFieldValue('endTime', null);
  };

  return (
    <>
      <Box sx={[container, align]}>
        <Typography sx={[counterValueText, titleStyle]}>
          {strings.offer}
        </Typography>
        <Box sx={divider} />
      </Box>

      <Box sx={itemsContainer}>
        {config.map((item) => {
          const { key, title, value } = item ?? {};

          return (
            <Box key={key} sx={[container, align, labelContainer]}>
              <Typography sx={[counterText]}>{title}</Typography>
              <Typography sx={[counterText, counterValueText]}>
                {value}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box sx={[container, align]}>
        <Typography sx={[counterValueText, titleStyle]}>
          {strings.counterOffer}
        </Typography>
        <Box sx={divider} />
      </Box>

      <Box sx={timeContainer}>
        <DateTimeRange
          customStartHandler={handleNegativeDates}
          startPlaceholder="00:00"
          endPlaceholder="00:00"
          formik={formik}
          horizontal={false}
          startName="startTime"
          startNameLabel={strings.startTime}
          endName="endTime"
          endNameLabel={strings.endTime}
          usePreFilledColors
        />
      </Box>

      <AllowanceWorkshift formik={formik} isCounterOffer />

      <NotesField formik={formik} />
    </>
  );
};

export default SubmitCounterOffer;
