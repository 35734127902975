import {
  IAdminPharmacistDetailsSearchData,
  IAdminPharmacistListBaseData,
  TypeOfUser,
  representNullValue,
} from '@pharmaplan/common';
import { idStore } from '@pharmaplan/common/helpers/ServiceHandler';
import {
  Constants,
  DynamicTableCell,
  OtherScreens,
  SoftwareExperience,
} from '../../../helpers/Constants';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import strings from '../../../localization';
import { IMenuActions } from '../../common/CustomAvatarSection/CustomAvatarSection';
import { getDayMonthDateYear } from '../../Reports/helpers';
import { IPharmacistListActionsParams } from './PharmacistList';

const { text, customLink, customMenu, button } = DynamicTableCell;
const { text: textVariant } = Constants.variant;

export const activePharmacistListHeaders = () =>
  [
    { key: '2', label: strings.pharmacistName, sortingKey: 'name' },
    { key: '3', label: strings.email, sortingKey: 'email' },
    { key: '4', label: strings.mobile, sortingKey: 'mobile' },
    { key: '5', label: strings.city, sortingKey: 'city' },
    {
      key: '6',
      label: strings.licenseNumber,
      isCenterAligned: true,
      sortingKey: 'licenseNumber',
    },
    {
      key: '7',
      label: strings.watchlist,
      isCenterAligned: true,
      sortingKey: 'watchlistCount',
    },
    { key: '8', label: strings.action, isCenterAligned: true },
  ];

export const deactivePharmacistListHeaders = () =>
  [
    { key: '2', label: strings.pharmacistName, sortingKey: 'name' },
    { key: '3', label: strings.email, sortingKey: 'email' },
    { key: '4', label: strings.mobile, sortingKey: 'mobile' },
    { key: '5', label: strings.deactivationDate, sortingKey: 'lastActivityDate' },
    { key: '6', label: strings.action, isCenterAligned: true },
  ];

export const hiddenPharmacistListHeaders = () =>
  [
    { key: '2', label: strings.pharmacistName, sortingKey: 'name' },
    { key: '3', label: strings.email, sortingKey: 'email' },
    { key: '4', label: strings.mobile, sortingKey: 'mobile' },
    {
      key: '5',
      label: `${strings.updated} ${strings.date}`,
      sortingKey: 'lastActivityDate',
    },
    { key: '6', label: strings.action, isCenterAligned: true },
  ];

export const deletedPharmacistListHeaders = () =>
  [
    { key: '2', label: strings.pharmacistName, sortingKey: 'name' },
    { key: '3', label: strings.email, sortingKey: 'email' },
    { key: '4', label: strings.mobile, sortingKey: 'mobile' },
    {
      key: '5',
      label: `${strings.deletion} ${strings.date}`,
      sortingKey: 'deletedDate',
    },
  ];

export const pharmacistSearchedDetailHeaders = () =>
  [
    { key: '1', label: strings.pharmacistName },
    { key: '2', label: strings.mobile },
    { key: '3', label: strings.email },
    { key: '4', label: strings.city },
    { key: '5', label: strings.pharmacistCapacity, isCenterAligned: true },
    { key: '6', label: strings.licenseNumber, isCenterAligned: true },
    { key: '7', label: strings.software },
    { key: '8', label: strings.level },
  ];

export const activePharmacistListRows = (
  data: Array<IAdminPharmacistListBaseData>,
  goToUserProfile: IUserProfileLinkSignature,
  goToWatchlists: (pharmacistId: string) => () => void,
  actions?: (params: IPharmacistListActionsParams) => Array<IMenuActions>,
) =>
  data.map((item) => {
    const { mobile, city, name, email, licenseNumber, watchlistCount } = item ?? {};
    const id = (item?.[idStore.id as keyof typeof item] as string) ?? '';
    const isWatchlistZero = watchlistCount === 0;

    return {
      key: id,
      data: [
        {
          key: `1-${id}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: id,
              userType: TypeOfUser.pharmacist,
            }),
          },
          type: customLink,
        },
        {
          key: `2-${id}`,
          maxWidth: 250,
          value: email,
          type: text,
        },
        {
          key: `3-${id}`,
          value: mobile,
          type: text,
        },
        {
          key: `4-${id}`,
          value: city,
          type: text,
        },
        {
          key: `5-${id}`,
          value: licenseNumber,
          type: text,
          isCenterAligned: true,
        },
        isWatchlistZero
          ? {
            key: `8-${id}`,
            value: representNullValue,
            type: text,
            isCenterAligned: true,
          }
          : {
            key: `6-${id}`,
            isCenterAligned: true,
            value: {
              label: watchlistCount,
              handlePress: goToWatchlists(id),
            },
            type: customLink,
          },
        {
          key: `7-${id}`,
          value: {
            customMenuActions: actions?.({
              pharmacistId: id,
              pharmacistName: name,
            }),
          },
          type: customMenu,
        },
      ],
    };
  });

export const hiddenPharmacistListRows = (
  data: Array<IAdminPharmacistListBaseData>,
  goToUserProfile: IUserProfileLinkSignature,
  showPharmacistDialog: (pharmacistId: string) => () => void,
) =>
  data?.map((item) => {
    const { email, lastActivityDate, name, mobile } = item ?? {};
    const id = (item?.[idStore.id as keyof typeof item] as string) ?? '';

    const date = getDayMonthDateYear(lastActivityDate ?? '');
    return {
      key: id,
      data: [
        {
          key: `1-${id}`,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: id,
              userType: TypeOfUser.pharmacist,
            }),
          },
          type: customLink,
        },
        {
          key: '2',
          value: email,
          type: text,
        },
        {
          key: '3',
          value: mobile,
          type: text,
        },
        {
          key: '4',
          value: date,
          type: text,
        },
        {
          key: `5-${id}`,
          value: {
            label: strings.unhide,
            handlePress: showPharmacistDialog(id),
            variant: textVariant,
          },
          show: true,
          type: button,
        },
      ],
    };
  });

export const deactivatedPharmacistListRows = (
  data: Array<IAdminPharmacistListBaseData>,
  goToUserProfile: IUserProfileLinkSignature,
  activateDialog: (pharmacistId: string) => () => void,
) =>
  data?.map((item) => {
    const { email, lastActivityDate, name, mobile } = item ?? {};
    const id = (item?.[idStore.id as keyof typeof item] as string) ?? '';
    const date = getDayMonthDateYear(lastActivityDate ?? '');

    return {
      key: id,
      data: [
        {
          key: `1-${id}`,
          value: {
            maxWidth: 200,
            label: name,
            handlePress: goToUserProfile({
              personnelId: id,
              userType: TypeOfUser.pharmacist,
            }),
          },
          type: customLink,
        },
        {
          key: '2',
          value: email,
          maxWidth: 200,
          type: text,
        },
        {
          key: '3',
          value: mobile,
          type: text,
        },
        {
          key: '4',
          value: date,
          type: text,
        },
        {
          key: `5-${id}`,
          value: {
            label: strings.activate,
            handlePress: activateDialog(id),
            variant: textVariant,
          },
          type: button,
          show: true,
        },
      ],
    };
  });

export const deletedPharmacistListRows = (
  data: Array<IAdminPharmacistListBaseData>,
  goToUserProfile: IUserProfileLinkSignature,
) =>
  data?.map((item) => {
    const { userId, email, deletedDate, name, mobile } = item ?? {};
    const id = (item?.[idStore.id as keyof typeof item] as string) ?? '';

    const date = getDayMonthDateYear(deletedDate);
    return {
      key: id,
      data: [
        {
          key: `1-${id}`,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: userId,
              userType: TypeOfUser.pharmacist,
              fromScreen: OtherScreens.DeletedPharmacistList,
            }),
          },
          type: customLink,
        },
        {
          key: '2',
          value: email,
          type: text,
        },
        {
          key: '3',
          value: mobile,
          type: text,
        },
        {
          key: '4',
          value: date,
          type: text,
        },
      ],
    };
  });

export const searchedDetailsRow = (
  data: Array<IAdminPharmacistDetailsSearchData>,
  goToUserProfile: IUserProfileLinkSignature,
) =>
  data?.map((item) => {
    const {
      email,
      name,
      mobile,
      city,
      capacityFrom,
      softwareId,
      expLevel,
      capacityTo,
      licenseNumber,
      software,
    } = item ?? {};
    const id = (item?.[idStore.id as keyof typeof item] as string) ?? '';

    const pharmacistCapacity = `${capacityFrom} - ${capacityTo}`;

    return {
      key: id + softwareId,
      data: [
        {
          key: `1-${id}`,
          maxWidth: 200,
          value: {
            label: name,
            handlePress: goToUserProfile({
              personnelId: id,
              userType: TypeOfUser.pharmacist,
            }),
          },
          type: customLink,
        },
        {
          key: '2',
          value: mobile,
          type: text,
        },
        {
          key: '3',
          value: email,
          maxWidth: 200,
          type: text,
        },
        {
          key: '4',
          value: city,
          type: text,
        },
        {
          key: '5',
          value: pharmacistCapacity,
          isCenterAligned: true,
          type: text,
        },
        {
          key: '6',
          value: licenseNumber,
          isCenterAligned: true,
          type: text,
        },
        {
          key: '7',
          value: software,
          type: text,
        },
        {
          key: '8',
          value: SoftwareExperience()[expLevel - 1],
          type: text,
        },
      ],
    };
  });
