import React, { FC, useEffect } from 'react';

import { Box } from '@mui/material';

import {
  adminActions,
  adminDeletedUserProfile,
  getPharmacyDetails,
  HelpTypes,
  PermissionsOfAdmin,
  pharmacyDetails,
} from '@pharmaplan/common';

import CollapsibleCard from '../../../../common/CollapsibleCard';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import useGetItems from '../../../../../hooks/useGetItems';
import {
  OtherScreens,
  SecondaryAdminNavigation,
} from '../../../../../helpers/Constants';
import {
  ICustomBack,
  IFromScreen,
  setBack,
  setFooterShow,
} from '../../../../../actions/drawerActions';
import AdminPharmacyCard from '../../../../common/AdminPharmacyCard';
import usePharmacistProfileActions from '../../../../../hooks/PharmacistProfile/usePharmacistProfileActions';
import useAdminPermissions from '../../../../../hooks/Admin/useAdminPermissions';

import ProfileContent from '../../ProfileContent';
import { pharmacyProfileConfig } from './helper';

interface IPharmacyViewProfile {
  pharmacyId: string;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: ICustomBack;
  enableActions?: boolean;
}

const { DeletedPendingUsers, DeletedPharmacyList } = OtherScreens;
const { pendingActivation } = SecondaryAdminNavigation;

const PharmacyViewProfile: FC<IPharmacyViewProfile> = ({
  pharmacyId,
  fromScreen,
  hasOwner,
  back,
  enableActions,
}) => {
  const dispatch = useAppDispatch();
  const { handleButton } = usePharmacistProfileActions(HelpTypes.pharmacy);
  const { can } = useAdminPermissions();

  const canActivate = can(PermissionsOfAdmin.Activation);

  const details = useAppSelector(pharmacyDetails);
  const { pharmacy, softwares, contactPreferences } = details ?? {};
  const { logoId, languageId, softwareId, selectedContactPreferences } = pharmacy ?? {};

  const isDeletedPending = fromScreen === DeletedPendingUsers;
  const fromPendingActivations = fromScreen === pendingActivation;
  const isDeletedPharmacyList = fromScreen === DeletedPharmacyList;

  const isDeletedList = isDeletedPending || isDeletedPharmacyList;

  const successAction = isDeletedList
    ? adminActions.deletedUserProfile
    : adminActions.getPharmacyDetails;

  const {
    getImage,
    getLanguageName,
    getSoftwareName,
    getSelectedContactPreferences,
  } = useGetItems();

  const avatar = getImage(logoId);
  const language = getLanguageName(languageId);
  const software = getSoftwareName(softwares, softwareId);
  const selectedPreferences = getSelectedContactPreferences(
    contactPreferences,
    selectedContactPreferences,
  );

  useEffect(() => {
    if (isDeletedList) {
      dispatch(
        adminDeletedUserProfile({
          userId: pharmacyId,
          approved: !!isDeletedPharmacyList,
        }),
      );
    } else {
      dispatch(getPharmacyDetails(pharmacyId));
    }
    dispatch(setBack(back ?? null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const showButton = fromPendingActivations && enableActions && canActivate;
    if (showButton) {
      handleButton(pharmacyId);
    } else {
      dispatch(setFooterShow(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AdminPharmacyCard
        pharmacyId={pharmacyId}
        fromScreen={fromScreen}
        avatar={avatar}
        hasOwner={hasOwner}
      />
      {pharmacyProfileConfig({
        details,
        language,
        software,
        successAction,
        selectedPreferences,
      }).map((item) => {
        const { key, title, keepExpanded, cardContent, customComponent } = item ?? {};

        return (
          <CollapsibleCard
            key={key}
            title={title}
            keepExpanded={keepExpanded}
            content={(
              <ProfileContent
                content={cardContent}
                customComponent={customComponent}
                successAction={successAction}
              />
            )}
          />
        );
      })}
    </Box>
  );
};

export default PharmacyViewProfile;
