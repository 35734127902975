import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import {
  enableChat,
  getTypeOfUser,
  getUserConfigurations,
  isUserFirstTimeLogin,
  TypeOfUser,
} from '@pharmaplan/common';

import useClientUnreadChats from '../../hooks/Admin/Chat/useClientUnreadChats';
import useHandleBookingCancel from '../../hooks/useHandleBookingCancel';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setDialog } from '../../reducers/dialogReducer';
import WelcomeToPharmaGap from '../WelcomeToPharmaGap';
import NavToolBar from '../Navbar/NavToolBar';
import FloatingChat from '../FloatingChat';

import Navbar from '../Navbar';
import classes from './style';

const { mainContainer, content, contentContainer } = classes;

const Home = () => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);

  const isFirstLogin = useAppSelector(isUserFirstTimeLogin);
  const isPharmacist = userType === TypeOfUser.pharmacist;

  useHandleBookingCancel();
  useClientUnreadChats();

  const isChatEnabled = useAppSelector(enableChat);

  useEffect(() => {
    dispatch(getUserConfigurations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPharmacist && isFirstLogin) {
      dispatch(
        setDialog({
          Component: <WelcomeToPharmaGap />,
          showCloseButton: true,
          heading: { title: '' },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLogin]);

  return (
    <Box sx={mainContainer} component="div">
      <Grid sx={contentContainer} item sm={11}>
        {/* TopBar */}
        <Box component="div">
          <Navbar BottomToolbar={<NavToolBar />} />
        </Box>
        {/* Content */}
        <Box sx={content}>
          <Outlet />
        </Box>
        {isChatEnabled && <FloatingChat />}
      </Grid>
    </Box>
  );
};

export default Home;
