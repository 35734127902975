import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { FormikValues, useFormik } from 'formik';

import {
  AllowancesNames,
  TypeOfUser,
  calendarDate,
  convertToDecimals,
  createWorkshift,
  getHourlyRate,
  getPharmacyCalendar,
  getRecurrence,
  getTypeOfUser,
  pharmacyActions,
  pharmacyHourlyRate,
  reset,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../actions/drawerActions';
import { makeReqParams, setHour } from '../../helpers/workshift/functions';
import { DurationTypes } from '../../helpers/Constants';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useRecurrence from '../useRecurrence';
import useSaveAndRemain from '../useSaveAndCreateAnother';
import { showSuccess } from '../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';

const {
  travelAllowanceType,
  accommodationAllowanceType,
  paidHourlyRate,
  accommodationAllowance,
  mealAllowance,
  travelAllowanceAmount,
  travelAllowanceRatePerKm,
  emergencyFees,
} = AllowancesNames;

const { pharmacist, pharmacy } = TypeOfUser;
const { notRepeat } = DurationTypes;

const todaysISO = DateTime.now().toISO({ includeOffset: false }) ?? '';

const useAddWorkshift = (date: string | null) => {
  const dispatch = useAppDispatch();
  const { saveAndRemain, handleSetSave, resetSaveAndRemain } = useSaveAndRemain();

  const recurrence = useAppSelector(getRecurrence);
  const cDate = useAppSelector(calendarDate);
  const hourlyRate = useAppSelector(pharmacyHourlyRate);
  const userType = useAppSelector(getTypeOfUser);
  const createSuccess = useAppSelector((state) =>
    successSelector([pharmacyActions.createWorkshift], state));

  const luxonDate = DateTime.fromISO(date || todaysISO);
  const parsedDate = date ? DateTime.fromISO(date) : null;

  const isPharmacy = userType === pharmacy;
  const { hourlyRate: currentHourlyRate } = hourlyRate ?? {};

  const onSubmit = (values: FormikValues) => {
    // NOTE: THE ORDER MATTERS
    dispatch(
      createWorkshift({
        typeReplacement: 0,
        travelAllowanceTypes: values[travelAllowanceType],
        accommodationAllowanceType: values[accommodationAllowanceType],
        paidHourlyRate: values[paidHourlyRate],
        travelAllowanceRatePerKm: parseFloat(values[travelAllowanceRatePerKm]),
        travelAllowanceAmount: parseFloat(values[travelAllowanceAmount]),
        mealAllowance: parseFloat(values[mealAllowance]),
        accommodationAllowance: parseFloat(values[accommodationAllowance]),
        ...makeReqParams(values, recurrence),
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      datePicker: parsedDate,
      selectedDate: luxonDate,
      allDay: false,
      startTime: null,
      endTime: null,
      notes: '',
      recurrence: notRepeat,

      [travelAllowanceType]: [],
      [accommodationAllowanceType]: '',

      [paidHourlyRate]: '',
      [travelAllowanceRatePerKm]: '',
      [travelAllowanceAmount]: '',
      [accommodationAllowance]: '',
      [mealAllowance]: '',
      [emergencyFees]: '',
      replacement: pharmacist,
    },
    onSubmit,
  });
  console.log(formik.errors);

  const { setFieldValue, handleSubmit } = formik ?? {};
  useRecurrence(formik);

  const handleReset = () => {
    dispatch(reset());
    if (saveAndRemain) {
      formik.resetForm();
      formik.setFieldValue('selectedDate', luxonDate);
      showSuccess(dispatch, strings.createWorkshift);
      resetSaveAndRemain();
      return;
    }
    dispatch(resetDrawer());
  };

  const setUserWorkTimings = () => {
    setFieldValue('startTime', setHour(luxonDate, 8));
    setFieldValue('endTime', setHour(luxonDate, 22));
  };

  useEffect(() => {
    dispatch(setPagination({ show: false }));
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.createWorkshift,
          onClick: handleSubmit,
        },
        secondaryButton: {
          label: strings.saveAndCreate,
          onClick: handleSetSave(handleSubmit),
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createSuccess) {
      dispatch(getPharmacyCalendar(cDate));
      handleReset();
      dispatch(resetStatus());
    }
    isPharmacy && dispatch(getHourlyRate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  useEffect(() => {
    setFieldValue(paidHourlyRate, convertToDecimals(currentHourlyRate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHourlyRate]);

  useEffect(() => {
    if (formik.values.datePicker) {
      formik.setFieldValue('selectedDate', formik.values.datePicker);
    }
  }, [formik.values.datePicker]);

  return { setUserWorkTimings, formik };
};

export default useAddWorkshift;
