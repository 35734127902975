import React, { ReactNode } from 'react';

import { FormikProps } from 'formik';
import {
  Box,
  FilledInput,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Theme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, SystemStyleObject } from '@mui/system';
import { Constants, TextFieldVariants } from '../../../helpers/Constants';
import IDropdownObject from '../../../types/ICustomDropdown';

import Checkbox from '../Checkbox';
import useTextField from '../../../hooks/useTextField';
import classes from './style';

type ICustomDropdown = {
  usePreFilledColors?: boolean;
  id?: string;
  name: string;
  menuItems: Array<IDropdownObject>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  placeholder?: string;
  label?: string;
  fullWidth?: boolean;
  isRecurrence?: boolean;
  customClass?: Record<string, unknown>;
  horizontal?: boolean;
  onClickMenuItem?: (val: string) => void;
  disabled?: boolean;
  multiple?: boolean;
  imageMenu?: boolean;
  requireLabel?: boolean;
  showPlaceholder?: boolean;
  radioDropdown?: boolean;
  errorContainer?: boolean;
  customMenuStyles?: SxProps<Theme>;
  labelStyles?: SxProps<Theme>;
  variant?: TextFieldVariants;
  onClose?: () => void;
};

const defaultProps = {
  id: '',
  fullWidth: true,
  label: '',
  placeholder: Constants.null,
  customClass: {},
  horizontal: false,
  multiple: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickMenuItem: () => {},
  disabled: false,
  imageMenu: false,
  isRecurrence: false,
  requireLabel: false,
  showPlaceholder: false,
  errorContainer: true,
};

const CustomDropdown = ({
  usePreFilledColors,
  id,
  label,
  name,
  formik,
  menuItems,
  placeholder,
  fullWidth,
  customClass,
  multiple,
  horizontal,
  requireLabel,
  onClickMenuItem,
  disabled,
  imageMenu,
  isRecurrence,
  showPlaceholder,
  radioDropdown,
  errorContainer,
  customMenuStyles,
  labelStyles,
  variant,
  onClose,
}: ICustomDropdown) => {
  const errors = formik?.errors[name] && formik?.touched[name] && formik?.errors[name];
  const initValue = multiple || imageMenu ? [] : '';

  const parseValue = (value: any) =>
    (typeof value === 'object' && !multiple && value !== null
      ? JSON.stringify(value)
      : value);

  const { style } = useTextField({
    value: parseValue(formik?.values?.[name]) || initValue,
    usePreFilledColors,
  });

  const handleValueRender = (renderValue: any) => {
    const itemValue = parseValue(renderValue);
    const isArray = typeof menuItems?.[0] === 'string';
    const placeHolder: (string | undefined)[] = [];
    const getLabel = (selected: any) => {
      if (isArray) {
        placeHolder.push(selected);
      } else {
        const itemLabel = menuItems?.find(
          (item) =>
            parseValue(item.key) === selected,
        )?.label;
        placeHolder.push(itemLabel);
      }
    };
    switch (true) {
      case multiple && !imageMenu:
        itemValue?.forEach((selectedItem: any) => {
          getLabel(selectedItem);
        });
        return placeHolder.join(', ');
      case multiple && imageMenu: {
        itemValue?.forEach((selectedItem: any) =>
          getLabel(selectedItem));
        const imagesArray = [] as any;
        placeHolder.forEach((item) => {
          imagesArray.push(
            <img style={classes.imageMenuItem} src={item} alt="dropdown" />,
          );
        });
        if (imagesArray?.length > 0) {
          return imagesArray.map((image: any) =>
            image);
        }
        return null;
      }
      case showPlaceholder && !formik.values[name]:
        return placeholder;
      default: {
        getLabel(itemValue);
        return placeHolder.join(', ');
      }
    }
  };

  const isFilled = variant === TextFieldVariants.filled;
  const input = isFilled ? (
    <FilledInput placeholder={placeholder} />
  ) : (
    <OutlinedInput />
  );

  return (
    <>
      <Box
        id={id}
        component="div"
        sx={[
          horizontal ? classes.horizontalDropdown : {},
          customClass as SystemStyleObject,
        ]}
      >
        {label && (
          <InputLabel>
            {label}
            {requireLabel && (
              <Box component="span" sx={classes.customTextFieldRequireLabel}>
                *
              </Box>
            )}
          </InputLabel>
        )}

        <Select
          sx={[
            !formik.values[name] && showPlaceholder
              ? classes.placeholderText
              : {},
            {
              '& .MuiSelect-select': style,
            },
            isFilled ? classes.customFilledInput : {},
          ]}
          fullWidth={fullWidth}
          disabled={disabled}
          SelectDisplayProps={{
            style: imageMenu ? classes.imageStyle : {},
          }}
          value={parseValue(formik?.values?.[name]) || initValue}
          multiple={multiple}
          MenuProps={{
            ...(!isRecurrence
              ? {
                sx: [classes.removeFocus, customMenuStyles] as SxProps<Theme>,
              }
              : null),
          }}
          error={!!errors}
          displayEmpty={showPlaceholder}
          IconComponent={ExpandMoreIcon}
          onChange={(e) =>
            formik?.setFieldValue(name, e.target.value)}
          input={input}
          renderValue={handleValueRender}
          placeholder={placeholder}
          onClose={onClose}
        >
          {menuItems?.map((item) => {
            const itemLabel = item.label || item;
            return (
              <MenuItem
                key={item.key}
                sx={{ color: 'grey.800' }}
                value={parseValue(item.key) || item}
                onClick={(e) =>
                  onClickMenuItem?.(e.currentTarget?.dataset?.value ?? '')}
              >
                <>
                  {multiple && (
                    <Checkbox
                      checked={formik?.values[name]?.includes(item?.key)}
                    />
                  )}
                  {radioDropdown && (
                    <Radio
                      checked={formik?.values[name]?.includes(item?.key)}
                    />
                  )}
                  {imageMenu && (
                    <img
                      style={classes.imageMenuItem}
                      src={item.label}
                      alt="menu Item"
                    />
                  )}
                  <Box component="div" sx={labelStyles}>
                    {(!imageMenu && itemLabel) as string}
                  </Box>
                </>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      {errorContainer && (
        <FormHelperText style={classes.error}>
          {errors as ReactNode}
        </FormHelperText>
      )}
    </>
  );
};

CustomDropdown.defaultProps = defaultProps;

export default CustomDropdown;
